import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './assets/css/dark.css'
import Epicx_logo from './assets/img/Epicx_logo.png';

const Priceheader = () => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isNavbarMobile, setIsNavbarMobile] = useState(false);

  const toggleTheme = () => {
    setClickCount(prevCount => prevCount + 1);
    setIsDarkMode((prevMode) => !prevMode);
  };
  useEffect(() => {
    if (clickCount >= 2) {
      window.location.reload(); // Reload page after the second click
    }
  }, [clickCount]);
  useEffect(() => {  
    const loadTheme = async () => {
      if (isDarkMode) {
        console.log('true');
      } else {
         await import('./assets/css/light.css');
      }
    };
    loadTheme();
  }, [isDarkMode]);

  useEffect(() => {
    // Function to handle navbar link activation
    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      const navbarlinks = document.querySelectorAll('#navbar .scrollto');
      navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return;
        let section = document.querySelector(navbarlink.hash);
        if (!section) return;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      });
    };

    // Event listeners for scroll and load
    window.addEventListener('load', navbarlinksActive);
    window.addEventListener('scroll', navbarlinksActive);

    return () => {
      // Cleanup event listeners
      window.removeEventListener('load', navbarlinksActive);
      window.removeEventListener('scroll', navbarlinksActive);
    };
  }, []);

  useEffect(() => {
    // Function to handle header scroll
    const headerScrolled = () => {
      const selectHeader = document.querySelector('#header');
      if (!selectHeader) return;
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled');
      } else {
        selectHeader.classList.remove('header-scrolled');
      }
    };

    // Event listeners for scroll and load
    window.addEventListener('load', headerScrolled);
    window.addEventListener('scroll', headerScrolled);

    return () => {
      // Cleanup event listeners
      window.removeEventListener('load', headerScrolled);
      window.removeEventListener('scroll', headerScrolled);
    };
  }, []);

  useEffect(() => {
    // Function to handle back to top button
    const toggleBacktotop = () => {
      const backtotop = document.querySelector('.back-to-top');
      if (!backtotop) return;
      if (window.scrollY > 100) {
        backtotop.classList.add('active');
      } else {
        backtotop.classList.remove('active');
      }
    };

    // Event listeners for scroll and load
    window.addEventListener('load', toggleBacktotop);
    window.addEventListener('scroll', toggleBacktotop);

    return () => {
      // Cleanup event listeners
      window.removeEventListener('load', toggleBacktotop);
      window.removeEventListener('scroll', toggleBacktotop);
    };
  }, []);


  const handleMobileNavToggle = () => {
    setIsNavbarMobile(prevState => !prevState);
  };

  // Function to handle scroll with offset on links with a class name .scrollto
  const handleScrollTo = (e) => {
    e.preventDefault();
    const { hash } = e.target;
    const navbar = document.querySelector('#navbar');
    if (!hash || !navbar) return;
    if (navbar.classList.contains('navbar-mobile')) {
      navbar.classList.remove('navbar-mobile');
      const mobileNavToggle = document.querySelector('.mobile-nav-toggle');
      if (mobileNavToggle) {
        mobileNavToggle.classList.toggle('bi-list');
        mobileNavToggle.classList.toggle('bi-x');
      }
    }
    const offset = document.querySelector('#header').offsetHeight - 10;
    const elementPos = document.querySelector(hash).offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    });
  };

  return (
    <header id="header" className='header fixed-top'>
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
      <Link to='/' className="logo d-flex align-items-center">
          <img src={Epicx_logo} alt="Epic-X Logo" />
        </Link>
        <nav id="navbar" className={` ${isNavbarMobile ? 'navbar-mobile' : 'navbar'}`}>
        <ul>
          <li>
            <Link to='/' className="nav-link scrollto active" smooth>Home</Link>
          </li>
          <li>
            <Link to='/#about' className="nav-link scrollto"smooth>About</Link>
          </li>
          <li>
            <Link className="nav-link scrollto" to="/#vismis"smooth>Vision and Mission</Link>
          </li>
          <li>
            <div
                  className="hover-element"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                 <a className="nav-link">Pricing</a>
                 {isHovered && (
                   <div className="dropdown-list">
                    <Link to='/PopupPrice' className="nav-link"><div>PopUp</div></Link>
                    <Link to='/ChatPrice' className="nav-link"><div>Chat</div></Link>
                    <Link to='/EmailPrice' className="nav-link"><div>Review</div></Link>
                    <Link to='/b2bPrice' className="nav-link"><div>B2B</div></Link>
                    <Link to='/b2cPrice' className="nav-link"><div>B2C</div></Link>
                    <Link to='/All' className="nav-link"><div>All</div></Link>                     
                   </div>
                  )}
          </div>
          </li>
          <li>
            <Link className="nav-link scrollto" to="/#ecom-serv" >Services</Link>
          </li>
          <li>
            <Link className="nav-link scrollto" to="/#partner">Partners</Link>
          </li>
          <li>
            <Link className="nav-link scrollto" to="/#testimonials">Clients</Link>
          </li>
          <li>
            <Link className="nav-link scrollto" to="/#contact">Contact</Link>
          </li>
            <li>
              <button id="toggle-theme" onClick={toggleTheme}>
                {isDarkMode 
                ?<i id="icon-light" className="ri-sun-line"></i> 
                : <i id="icon-dark" className="ri-moon-line"></i>}
              </button>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" style={{ color: isNavbarMobile ? 'white' : '#012970' }}  onClick={handleMobileNavToggle}></i>
        </nav>
      </div>
    </header>
  );
};

export default Priceheader;