import React, { useContext } from 'react';
import { useState } from 'react';
import './Pricing.css';
import tic from '../assets/img/blue_check.png'
import { ContextProvider } from '../Context/SharedStateContext';

const ChatPrice = () => {
  const { setPlanSelected, setPlanTypeSelected } = useContext(ContextProvider)
  const [isYearly, setIsYearly] = useState(false);

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };
const monthprice ={
  free:0,
  growth:29,
  professional:59,
  premium:499,
}
const yearprice ={
  free:0,
  growth:299,
  professional:659,
  premium:5499,
}
  function handleGetStarted(plans) {
    const product=`chat`;
    let pricingType='';
    if(plans==='free'){
      pricingType='both'
    }else{
    pricingType = isYearly ? 'year' : 'month';
    }
    setPlanSelected(plans)
    setPlanTypeSelected(pricingType)
    window.location.href = `/Register?product=${product}&plan=${plans}&type=${pricingType}`;
  };

  return (
    <div className="container d-flex mt-5 mb-4" style={{height:'auto'}}>
      <div className="mt-5 w-100">
        <div className="d-flex align-item-center justify-content-center mt-5">
            <div className="d-flex flex-column text-center">
              <h2>Streamline your teamwork. Start free.</h2>
              <p>Choose the perfect plan for your business needs</p>
            </div>
        </div>
        <div className="d-flex flex-column mt-5">
        <div className="d-flex align-item-center justify-content-center">
          <div className="back">
                <label className={`toggle ${isYearly ? 'yearly' : 'monthly'}`}>
                  <input type="checkbox" checked={isYearly} onChange={handleToggle} />
                  <span className="toggle-text">Monthly</span>
                </label>

                <label className={`toggle ${!isYearly ? 'yearly' : 'monthly'}`}>
                  <input type="checkbox" checked={!isYearly} onChange={handleToggle} />
                  <span className="toggle-text">Yearly</span>
                </label>
          </div>
        </div>
        <div className='d-flex gap-3 align-item-center justify-content-center mt-3' style={{width:'100%',flexWrap:'wrap'}}>
            <div className="pricing-card-1">
              <div className="p-4">
                <h4 className="bold">Free</h4>
                <p>Best for personal use</p>
                <div className="price-display mt-5">
                   {isYearly ? (
                    <div className="d-flex" style={{background:'transparent'}}>
                    <h2>${yearprice.free}</h2>
                    <p className='mt-2'>/year</p>
                    </div>
                  ) : (
                    <div className="d-flex" style={{background:'transparent'}}>
                    <h2>${monthprice.free}</h2>
                    <p className='mt-2'>/month</p>
                    </div>
                  )}
                </div>
             </div>
                <div className="p-4">
                  <h6 className='bold'>What you get:</h6>
                  <div className="pricing-list d-flex flex-column">
                      <li>
                        <img src={tic} alt="" className="tic" />
                        <p>Live chat</p>
                      </li>
                      <li>
                        <img src={tic} alt="" className="tic" />
                        <p>Ticketing</p>
                      </li>
                      <li>
                        <img src={tic} alt="" className="tic" />
                        <p>Social media integrations</p>
                      </li>
                    </div>
                </div>
                <div className="d-flex align-item-center justify-content-center mt-5">
                  <button className="btn-start mt-5" onClick={() => handleGetStarted('free')}>Get Started</button>
                </div>
            </div>
            <div className="d-flex flex-column pricing-card-2">
              <div className="p-4">
                <h4 className="bold">Growth</h4>
                <p>Best for personal use</p>
                <div className="price-display mt-5">
                    {isYearly ? (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${yearprice.growth}</h2>
                        <p className='mt-2'>/year</p>
                        </div>
                      ) : (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${monthprice.growth}</h2>
                        <p className='mt-2'>/month</p>
                        </div>
                      )}
                </div>
             </div>
             <div className="p-4">
                  <h6 className='bold'>All free features, plus:</h6>
                  <div className="pricing-list d-flex flex-column">
                      <li>
                        <img src={tic} alt="" className="tic" />
                        <p>Basic analytics</p>
                      </li>
                      <li>
                        <img src={tic} alt="" className="tic" />
                        <p>Live visitors list</p>
                      </li>
                      <li>
                        <img src={tic} alt="" className="tic" />
                        <p>Operating hours</p>
                      </li>
                      <li>
                        <img src={tic} alt="" className="tic" />
                        <p>Live chat support (24/5)</p>
                      </li>
                    </div>
                </div>
                <div className="d-flex align-item-center justify-content-center mt-5">
                <button className="btn-start mt-2" onClick={() => handleGetStarted('growth')}>Get Started</button>
                </div>
            </div>
            <div className="d-flex flex-column pricing-card-3">
              <div className="p-4">
                <h4 className="bold">Professional</h4>
                <p>Best for personal use</p>
                <div className="mt-5 price-display">
                    {isYearly ? (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${yearprice.professional}</h2>
                        <p className='mt-2'>/year</p>
                        </div>
                      ) : (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${monthprice.professional}</h2>
                        <p className='mt-2'>/month</p>
                        </div>
                      )}
                </div>
             </div>
             <div className="p-4">
                  <h6 className='bold'>All free features, plus:</h6>
                     <div className="pricing-list d-flex flex-column">
                        <li>
                          <img src={tic} alt="" className="tic" />
                          <p>Up to 1000 Handled Conversations</p>
                        </li>
                        <li>
                          <img src={tic} alt="" className="tic" />
                          <p>Advanced analytics</p>
                        </li>
                        <li>
                          <img src={tic} alt="" className="tic" />
                          <p>Tidio power features</p>
                        </li>
                        <li>
                          <img src={tic} alt="" className="tic" />
                          <p>No Tidio branding (add-on)</p>
                        </li>
                        <li>
                          <img src={tic} alt="" className="tic" />
                          <p>Permissions</p>
                        </li>
                      </div>
                </div>
                <div className="d-flex align-item-center justify-content-center">
                <button className="btn-start" onClick={() => handleGetStarted('professional')}>Get Started</button>
                </div>
            </div>
            <div className="d-flex flex-column pricing-card-4">
              <div className="p-4">
                <h4 className="bold">Premium</h4>
                <p>Best for personal use</p>
                <div className="price-display mt-5">
                    {isYearly ? (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${yearprice.premium}</h2>
                        <p className='mt-2'>/year</p>
                        </div>
                      ) : (
                        <div className="d-flex" style={{background:'transparent'}}>
                        <h2>${monthprice.premium}</h2>
                        <p className='mt-2'>/month</p>
                        </div>
                      )}
                </div>
             </div>
             <div className="p-4">
                  <h6 className='bold'>All free features, plus:</h6>
                  <div className="pricing-list d-flex flex-column">
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Custom Conversations limit</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Custom Lyro conversation limit</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Custom visitors reached limit</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Dedicated Success Manager</p>
                    </li>
                    <li>
                      <img src={tic} alt="" className="tic" />
                      <p>Training sessions</p>
                    </li>
                    </div>
                </div>
                <div className="d-flex align-item-center justify-content-center ">
                <button className="btn-start" onClick={() => handleGetStarted('premium')}>Get Started</button>
                </div>
            </div>
          </div>
        </div>
      </div>
     </div>
  );
};

export default ChatPrice;
