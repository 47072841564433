import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./assets/css/dark.css";
import Epicx_logo from "./assets/img/Epicx_logo.png";
import { ContextProvider } from "./Context/SharedStateContext";

const Header = () => {
  const { setProductSelected } = useContext(ContextProvider);

  const [isDarkMode, setIsDarkMode] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isNavbarMobile, setIsNavbarMobile] = useState(false);

  const toggleTheme = () => {
    setClickCount((prevCount) => prevCount + 1);
    setIsDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (clickCount >= 2) {
      window.location.reload(); // Reload page after the second click
    }
  }, [clickCount]);

  useEffect(() => {
    const loadTheme = async () => {
      if (!isDarkMode) {
        await import("./assets/css/light.css");
      }
    };
    loadTheme();
  }, [isDarkMode]);

  useEffect(() => {
    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      const navbarlinks = document.querySelectorAll("#navbar .scrollto");
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = document.querySelector(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    };

    window.addEventListener("load", navbarlinksActive);
    window.addEventListener("scroll", navbarlinksActive);

    return () => {
      window.removeEventListener("load", navbarlinksActive);
      window.removeEventListener("scroll", navbarlinksActive);
    };
  }, []);

  useEffect(() => {
    const headerScrolled = () => {
      const selectHeader = document.querySelector("#header");
      if (!selectHeader) return;
      if (window.scrollY > 100) {
        selectHeader.classList.add("header-scrolled");
      } else {
        selectHeader.classList.remove("header-scrolled");
      }
    };

    window.addEventListener("load", headerScrolled);
    window.addEventListener("scroll", headerScrolled);

    return () => {
      window.removeEventListener("load", headerScrolled);
      window.removeEventListener("scroll", headerScrolled);
    };
  }, []);

  useEffect(() => {
    const toggleBacktotop = () => {
      const backtotop = document.querySelector(".back-to-top");
      if (!backtotop) return;
      if (window.scrollY > 100) {
        backtotop.classList.add("active");
      } else {
        backtotop.classList.remove("active");
      }
    };

    window.addEventListener("load", toggleBacktotop);
    window.addEventListener("scroll", toggleBacktotop);

    return () => {
      window.removeEventListener("load", toggleBacktotop);
      window.removeEventListener("scroll", toggleBacktotop);
    };
  }, []);

  const handleMobileNavToggle = () => {
    setIsNavbarMobile((prevState) => !prevState);
  };

  const handleScrollTo = (e) => {
    e.preventDefault();
    const { hash } = e.target;
    const navbar = document.querySelector("#navbar");
    if (!hash || !navbar) return;
    if (isNavbarMobile) {
      setIsNavbarMobile(false);
    }
    const offset = document.querySelector("#header").offsetHeight - 10;
    const elementPos = document.querySelector(hash).offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: "smooth",
    });
  };

  return (
    <header id="header" className="header fixed-top">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <Link to="/" className="logo d-flex align-items-center">
          <img src={Epicx_logo} alt="Epic-X Logo" />
        </Link>
        <nav
          id="navbar"
          className={` ${isNavbarMobile ? "navbar-mobile" : "navbar"}`}
        >
          <ul>
            <li>
              <Link to="/" className="nav-link scrollto active" smooth="true">
                Home
              </Link>
            </li>
            <li>
              <a href="#about" className="nav-link scrollto" smooth="true">
                About
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#vismis" smooth="true">
                Vision and Mission
              </a>
            </li>
            <li>
              <div
                className="hover-element"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <a className="nav-link">Pricing</a>
                {isHovered && (
                  <div className="dropdown-list">
                    <Link
                      to="/PopupPrice"
                      className="nav-link"
                      onClick={() => setProductSelected("Popup")}
                    >
                      <div>PopUp</div>
                    </Link>
                    <Link
                      to="/ChatPrice"
                      className="nav-link"
                      onClick={() => setProductSelected("Chat")}
                    >
                      <div>Chat</div>
                    </Link>
                    <Link
                      to="/EmailPrice"
                      className="nav-link"
                      onClick={() => setProductSelected("Review")}
                    >
                      <div>Review</div>
                    </Link>
                    <Link
                      to="/b2bPrice"
                      className="nav-link"
                      onClick={() => setProductSelected("B2B")}
                    >
                      <div>B2B</div>
                    </Link>
                    <Link
                      to="/b2cPrice"
                      className="nav-link"
                      onClick={() => setProductSelected("B2C")}
                    >
                      <div>B2C</div>
                    </Link>
                    <Link
                      to="/All"
                      className="nav-link"
                      onClick={() => setProductSelected("All")}
                    >
                      <div>All</div>
                    </Link>
                  </div>
                )}
              </div>
            </li>
            <li>
              <a className="nav-link scrollto" href="#ecom-serv" smooth="true">
                Services
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#partner" smooth="true">
                Partners
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#testimonials"
                smooth="true"
              >
                Clients
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#contact" smooth="true">
                Contact
              </a>
            </li>
            <li>
              <button id="toggle-theme" onClick={toggleTheme}>
                {isDarkMode ? (
                  <i id="icon-light" className="ri-sun-line"></i>
                ) : (
                  <i id="icon-dark" className="ri-moon-line"></i>
                )}
              </button>
            </li>
          </ul>
          <i
            className="bi bi-list mobile-nav-toggle"
            style={isNavbarMobile ? { color: "white" } : {}}
            onClick={handleMobileNavToggle}
          ></i>
        </nav>
      </div>
    </header>
  );
};

export default Header;
