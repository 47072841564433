import React from 'react'

const All = () => {
  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
        <h3>Comming Soon..</h3>
    </div>
  )
}

export default All