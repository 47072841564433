import React, { useEffect, useState } from 'react'

const Unsubscribe = () => {
    const [storeName,setStoreName]=useState('')
    useEffect(()=>{
        const url = window.location.href;
        
        const currentUrl = new URL(url);
        
        const decode = currentUrl.searchParams.get('token');
        const data=atob(decode)
        const storename=data.split(';')
        setStoreName(storename[1])
    },[])

  return (
    <div style={{display:'flex',flexDirection:'column',gap:'10px',margin:'40px 400px'}}>
      <h1>{storeName}</h1>
      <h1 style={{fontSize:'20px',fontWeight:'bold'}}>
      You’ve unsubscribed from our mailing list.</h1>
        <p>You won’t receive any more marketing updates from us. You can subscribe again at any time.</p>
        
    </div>
  )
}

export default Unsubscribe
