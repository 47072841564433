import React from "react";
import guideVideo from "../../Assets/popupguide.mp4";

const PopupGuide = () => {
  const main = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };
  const videoContainer = {
    maxWidth: "600px",
  };
  return (
    <div style={main}>
      <h1>Popup Guide</h1>
      <div style={videoContainer}>
        <video controls muted playsInline width="100%" height="auto">
          <source src={guideVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default PopupGuide;
