import React from "react";
import './privacy.css'

const PrivacyPolicy = () => {
  const policySections = [
    "Information We Collect",
    "Our Customers and Site Users",
    "Users of Our Customer’s Web Sites",
    "How We Use Information",
    "How We Disclose Information",
    "Cookies and Other Tracking Mechanisms",
    "Clear GIFs, pixel tags, and other technologies. Clear GIFs are tiny graphics with a",
    "User-Generated Content",
    "Third-Party Links & Analytics",
    "Do Not Track Disclosure",
    "Interest-Based Advertising",
    "Third-Party Collection and Disclosure",
    "Access",
    "Data Retention",
    "Data Integrity, Purpose Limitation",
    "Security",
    "Marketing Communications",
    "CanSpam Act",
    "Your Choices",
    "Updating or Deleting Personal Information",
    "Opting out of Email or text Communications",
    "Legal",
    "Children",
    "Dispute Resolution",
    "Terms of Service",
    "Your Consent",
    "Contact Us",
    "EU Data Subjects",
    "Recourse, Privacy Complaints by EU Residents",
    "Contact Information",
    "Changes to this Policy",
  ];

const heading ={
    paddingTop:'10px',
    paddingBottom:'5px',
    fontWeight:'bold',
    fontSize:'20px',
    color:'#505050'
}

const contentStyle={
  fontFamily:'Inter',
  lineHeight: '27px',
  fontSize: '17px',
  letterSpacing:' 0.2px',
  color:'#505050',
}

const spanStyle ={
  paddingTop: "15px",
    paddingBottom: "15px",
    fontWeight: "bold",
    fontSize: '20px',
    display:'inline-block'
}

  return (
    <div className="container mt-5">
      <div className="privacyPolicy" style={{ margin: "100px 0",fontFamily:'inter' }}>
        <div>
          <h5 style={heading}>Epic-X Bharat Private Limited</h5>
          <h5 style={heading}>Privacy Policy</h5>
          <p style={contentStyle}>
            <span style={heading}>Effective Date:</span> 12/Nov/24
          </p>

          <p style={contentStyle}>
            Epic-X Bharat Private Limited, Inc. (“Epic-X Bharat Private
            Limited”, “we”, “us”, or “our”) recognizes the importance of
            privacy. We streamline the process of automating, analyzing, and
            personalizing an eCommerce store’s marketing campaigns through an
            all-in-one platform and data-driven marketing recommendations
            (collectively, the “Services”). In this Privacy Policy, we describe
            how we collect, use, and disclose information that we obtain about
            visitors (“Visitors”) to our website www.epic-x.io and any related
            subdomains (collectively, the “Site”); users who have registered
            accounts with us to use our Services (“Customers”); and the end
            users of our Customers’ eCommerce stores or individuals that
            Customers may wish to market to using our Services (“End Users”).
            Our use of “you” refers collectively to Visitors, Customers, and End
            Users.
          </p>

          <p style={contentStyle}>
            By using any of our Services, Visitors and Customers agree that
            their information, including personal information, will be
            collected, used, and disclosed as described in this Privacy Policy.
            Their use of our Services, and any dispute over privacy, is subject
            to this Privacy Policy and our terms of service, including its
            applicable limitations on damages and the resolution of disputes. We
            have no direct relationship with End Users, however, so our
            Customers are solely responsible for ensuring that they have the
            appropriate permission for us to collect, use, and disclose
            information about End Users.
          </p>

          <ul>
            {policySections.map((section, index) => (
              <li key={index}>{section}</li>
            ))}
          </ul>
        </div>

        <div>
          <span style={spanStyle}>Information We Collect</span>
          <p style={contentStyle}>You may visit our site anonymously.</p>
          <p style={contentStyle}>
            If you choose to register on our website, four categories of data to
            and on behalf of you will be processed:
          </p>
          <p style={contentStyle}>"Account Data"</p>
          <p style={contentStyle}>
            When you register for an account on our site, place an order,
            subscribe to our newsletter or respond to a survey, basic contact
            details are collected such as the e-mail address and name of your
            contact person, company name, address, phone number, VAT number,
            preferred language and currency, any purchase order number, any
            e-mail address of invoice receivers and masked credit card or bank
            account details.
          </p>
          <p style={contentStyle}>"Configuration Data"</p>

          <p style={contentStyle}>
            We collect your direct input to our cloud service Epic-X Bharat
            Private Limited (the “Service”) after login, like the domain name(s)
            of the website(s) where you implement the Service and configuration
            of the content, looks and behavior towards website visitors (“End
            Users”).
          </p>
          <p style={contentStyle}>"End User Data"</p>
          <p style={contentStyle}>
            Data generated by End Users browsing your website(s) using the
            Service. When an End User submits a consent from your website(s),
            the following data are automatically logged at Epic-X Bharat Private
            Limited:
          </p>
          <ul>
            <li style={contentStyle}>
              The End User’s IP number in anonymized form (last three digits are
              set to ‘0’).
            </li>
            <li style={contentStyle}>The date and time of the consent.</li>
            <li style={contentStyle}>User agent of the End User’s browser.</li>
            <li style={contentStyle}>The URL from which the consent was submitted.</li>
            <li style={contentStyle}>An anonymous, random and encrypted key value.</li>
            <li style={contentStyle}>The End User’s consent state, serving as proof of consent.</li>
          </ul>
          <p style={contentStyle}>
            The key and consent state are also saved in the End User’s browser
            in the first party cookie “CookieConsent” so that the website can
            automatically read and respect the End User’s consent on all
            subsequent page requests and future End User sessions for up to 12
            months. The key is used for proof of consent and an option to verify
            that the consent state stored in the End User’s browser is unaltered
            compared to the original consent submitted to Epic-X Bharat Private
            Limited.
          </p>
          <p style={contentStyle}>"System Generated Data"</p>
          <p style={contentStyle}>
            The Service automatically creates and stores meta data on basis of
            the other types of data, e.g.:
          </p>
          <ul>
            <li style={contentStyle}>
              Subscription data, like start date, latest invoice date and the
              result of a mandatory email validation. All new merchant accounts
              after Oct 1st 2019 invoices are stored outside of the system with
              our processor Stripe.com so that you may access any issued
              invoices from within Stripe.com.
            </li>
            <li style={contentStyle}>Aggregated statistical data on End User consents.</li>
          </ul>
          <p style={contentStyle}>
            Subscribers/Merchants can issue instructions to Epic-X Bharat
            Private Limited through configuration and/or execution of relevant
            functions offered by the Service from Epic-X backend. If a specific
            instruction regarding personal data cannot be carried out through
            the backend, you may send instructions to us through the email
            techsupport@epic-x.io
          </p>

          <span style={spanStyle}>Our Customers and Site Users</span>
          <p style={contentStyle}>
            We collect information about you directly from you, from third
            parties, and automatically through your interaction with our
            Services. We may combine information that we collect from these
            various sources.
          </p>

          <ul>
            <li style={contentStyle}>
              <b>Information We Collect Directly from You</b>
              <ul>
                <li style={contentStyle}>
                  We may collect information about you directly from you. For
                  example, if you create an account with us, we will collect
                  your name, email address, password, and store URL; if you pay
                  for any Services, we will also collect your payment card
                  information and billing address. We will also collect any
                  other information that you provide to us through our Services,
                  such as your name, email address, and the contents of your
                  message if you contact us through our Site.
                </li>
              </ul>
            </li>

            <li style={contentStyle}>
              <b>Information We Collect from Third Parties</b>
              <ul>
                <li style={contentStyle}>
                  We may collect information about you from third parties. For
                  example, if you are an End User, we will collect information
                  about you from our Customers. We will also collect information
                  about you from social media platforms, data aggregators, and
                  providers of marketing services. Epic-X Bharat Private Limited
                  is not responsible for the privacy practices of third parties
                  from which it receives information about you.
                </li>
              </ul>
            </li>

            <li style={contentStyle}>
              <b>Information We Collect Automatically</b>
              <ul>
                <li style={contentStyle}>
                  We automatically collect information about you through your
                  use of our Services, including, without limitation IP address,
                  location, browser type, domain name, the website that led you
                  to our Services, the website to which you go after leaving our
                  Services, the dates and times you access our Services, device
                  ID, and activities within our Services (e.g., links you click,
                  searches you run, etc.). Please see our cookies and tracking
                  section below for more information. In addition, we also
                  automatically collect information about your use of our
                  Services, such as transaction data (e.g., date and time of
                  purchase, amount of purchase), product data (e.g., product
                  purchased, category of product purchased), and behavioral data
                  (e.g., time spent engaging with a particular product). You
                  should know that we use our Marketing and Analytics Services
                  on our Site in order to obtain some of this information.
                </li>
              </ul>
            </li>

            <li style={contentStyle}>
              <b>Information We Collect From Social Networking Sites</b>
              <ul>
                <li style={contentStyle}>
                  If you choose to “Like” us, subscribe to our Twitter feed, or
                  connect with us on LinkedIn, we may receive information from
                  those third party sites. For example, when you “Like” us
                  through Facebook, we receive your Facebook user ID and the
                  pages you were visiting when you chose to “Like” us; we may
                  also collect any profile information that you share with
                  everyone. To review and manage the privacy settings for your
                  social networking accounts, you should refer to the privacy
                  settings for the social network.
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div>
          <span style={spanStyle}>Users of Our Customers’ Websites</span>
          <p style={contentStyle}>
            Epic-X Bharat Private Limited’s clients and customers (“Clients”)
            may use our Marketing and Analytics Services, which includes certain
            code, software, cookies, and other tracking mechanisms, and services
            to obtain information regarding the activities that users engage in
            while visiting their websites (“Client Analytics Data”). When a user
            visits a website that uses our Marketing and Analytics Service, code
            contained on the website contacts our servers and enables Epic-X
            Bharat Private Limited to collect Client Analytics Data. Some
            Clients may also use their own code to obtain analytics data about
            users of their Digital Properties, and then send such information to
            our servers. Client data is stored on Epic-X Bharat Private
            Limited’s web servers and databases for use in performing analysis
            and producing reports for Clients, and for other purposes explained
            in this Policy.
          </p>
          <p style={contentStyle}>
            Our Marketing and Analytics Services are used to collect and analyze
            behavioral, usage, and activity information about the use of our
            Clients’ Digital Properties. The Marketing and Analytics Services
            will collect certain non-personally identifiable analytics data,
            including (but not limited to) IP address and location information,
            website URL, referring URL, pages viewed (including page address,
            name and title, date and time of access, and length of time spent on
            each page), activities on each page (including links clicked and
            search terms entered), operating system, browser type, User Agent
            header information, web page performance information, campaign
            information, Java version, Flash version, resolution and screen
            information, and language information.
          </p>
          <p style={contentStyle}>
            Epic-X Bharat Private Limited Clients can also use the Marketing and
            Analytics Services to collect additional Client Analytics Data,
            (including personally identifiable data such as email addresses,
            postal addresses, and telephone numbers). The collection and use of
            Client Analytics Data by or on behalf of a Client, is subject to the
            Client’s Privacy Policy.
          </p>
        </div>

        <div>
          <span style={spanStyle}>How We Use Information</span>
          <p style={contentStyle}>
            We use the information that we collect, including personal
            information, for the following purposes:
          </p>

          <ul>
            <li style={contentStyle}>
              <strong>Providing and Improving Our Services.</strong> To provide
              and maintain our Services; to improve our Services; to develop new
              features or services; to perform technical operations, such as
              updating software; and for other customer service and support
              purposes.
            </li>
            <li style={contentStyle}>
              <strong>Research and Analytics.</strong> To analyze how you
              interact with our Services; to monitor and analyze usage and
              activity trends; and for other research, analytical, and
              statistical purposes.
            </li>
            <li style={contentStyle}>
              <strong>Marketing and Communications.</strong> To communicate with
              Customers about their use of our Services, including to send them
              updates, respond to their inquiries, and provide them with news
              and newsletters, special offers, promotions, and other information
              we think may interest them, and for other informational,
              marketing, or promotional purposes. Our communications with
              Customers may include communications via email and text. Please
              see our section regarding Your Choices below for more information
              about how to change your communications preferences.
            </li>
            <li style={contentStyle}>
              To better understand how users access and use our Site and
              Marketing and Analytics Services, in order to improve our Site and
              Marketing and Analytics Services and respond to user desires and
              preferences, and for other research and analytical purposes.
            </li>
            <li style={contentStyle}>
              To compile aggregate data derived from our Marketing and Analytics
              Services (including the Client Analytics Data of multiple Clients)
              to compile statistics, metrics, insights, and general trend data
              about Epic-X Bharat Private Limited, Site activity and analytics
              data (on an aggregate and individual basis) and the use of the
              Marketing and Analytics Services for, among other things, Epic-X
              Bharat Private Limited’s internal use, research, marketing and
              promotional purposes, and as part of our other analytics services.
            </li>
            <li style={contentStyle}>
              <strong>Protecting Rights and Interests.</strong> To protect the
              safety, rights, property, or security of Epic-X Bharat Private
              Limited, the Services, any third party, or the general public; to
              detect, prevent, or otherwise address fraud, security, or
              technical issues; to prevent or stop activity which Epic-X Bharat
              Private Limited, in its sole discretion, may consider to be, or to
              pose a risk of being, an illegal, unethical, or legally actionable
              activity; to use as evidence in litigation; and to enforce this
              Privacy Policy or our Terms of Service.
            </li>
            <li style={contentStyle}>
              <strong>Legal Compliance.</strong> To comply with applicable legal
              or regulatory obligations, including as part of a judicial
              proceeding; to respond to a subpoena, warrant, court order, or
              other legal process; or as part of an investigation or request
              from law enforcement or a governmental authority.
            </li>
          </ul>
        </div>

        <div>
          <span style={spanStyle}>How We Disclose Information</span>
          <p style={contentStyle}>
            We disclose the information that we collect, including personal
            information, as described to you at the time of collection or as
            consented by you before disclosure, or as follows:
          </p>

          <ul>
            <li style={contentStyle}>
              <strong>Affiliates.</strong> We may disclose information to
              current or future affiliates or subsidiaries for research,
              marketing, and other purposes consistent with this Privacy Policy.
            </li>
            <li style={contentStyle}>
              <strong>Service Providers.</strong> We may disclose information to
              our vendors, service providers, agents, or others who perform
              functions on our behalf. For example, we may disclose information
              to partners in our Epic-X Bharat Private Limited marketplace to
              help Customers expand their markets.
            </li>
            <li style={contentStyle}>
              <strong>Customers.</strong> We may disclose information about End
              Users to our Customers for purposes of providing our Services.
            </li>
            <li style={contentStyle}>
              <strong>Other Unaffiliated Third Parties.</strong> We may disclose
              your information to third parties, including so that those third
              parties may send marketing information to you.
            </li>
            <li style={contentStyle}>
              <strong>Business Transfers.</strong> We may disclose information
              to another entity in connection with, including during
              negotiations of, an acquisition or merger, sale or transfer of a
              business unit or assets, bankruptcy proceeding, or as part of any
              other similar business transfer.
            </li>
            <li style={contentStyle}>
              <strong>Protecting Rights and Interests.</strong> We may disclose
              information to protect the safety, rights, property, or security
              of Epic-X Bharat Private Limited, the Services, any third party,
              or the general public; to detect, prevent, or otherwise address
              fraud, security, or technical issues; to prevent or stop activity
              which Epic-X Bharat Private Limited, in its sole discretion, may
              consider to be, or to pose a risk of being, an illegal, unethical,
              or legally actionable activity; to use as evidence in litigation;
              and to enforce this Privacy Policy or our Terms of Service.
            </li>
            <li style={contentStyle}>
              <strong>Legal Compliance.</strong> We may disclose information to
              comply with applicable legal or regulatory obligations, including
              as part of a judicial proceeding; in response to a subpoena,
              warrant, court order, or other legal process; or as part of an
              investigation or request from law enforcement or a government
              official.
            </li>
            <li style={contentStyle}>
              <strong>Aggregate and De-Identified Information.</strong> We may
              disclose aggregate, anonymous, or de-identified information for
              marketing, advertising, research, compliance, or other purposes.
            </li>
          </ul>
        </div>

        <div>
          <span style={spanStyle}>Cookies and Other Tracking Mechanisms</span>
          <p style={contentStyle}>
            We or our service providers use cookies and other tracking
            mechanisms to track information about how you use our Services. We
            or our service providers may combine this information with other
            information, including personal information, we collect about you.
          </p>

          <ul>
            <li style={contentStyle}>
              <strong>Cookies.</strong> Cookies are alphanumeric identifiers
              that we transfer to a computer’s hard drive through a web browser
              for record-keeping purposes. Some cookies allow us to make it
              easier for you to navigate our Services, while others are used to
              enable a faster log-in process or to allow us to track your
              activities while using our Services. Most web browsers
              automatically accept cookies, but if you prefer, you can edit your
              browser options to block them in the future. The Help portion of
              the toolbar on most browsers provides information about how to
              prevent a computer from accepting new cookies, how to have the
              browser notify upon receiving a new cookie, or how to disable
              cookies altogether. Users who disable cookies will be able to
              browse certain areas of the Services, but some features may not
              function.
            </li>
            <li style={contentStyle}>
              <strong>Cookiebot.</strong> We use Cookiebot to manage all cookies
              on our website. Cookiebot brings this vision to life with three,
              fully automatic core functions that are easy to implement: cookie
              consent, cookie monitoring, and cookie control. Cookiebot enables
              true compliance with privacy legislations through respectful and
              transparent data exchange, based on consent between end-users and
              our website. For more info please see our Cookie Declaration here.
            </li>
          </ul>
        </div>
        <div>
          <span style={spanStyle}>Clear GIFs, Pixel Tags, and Other Technologies</span>
          <p style={contentStyle}>
            Clear GIFs are tiny graphics with a unique identifier, similar in
            function to cookies. In contrast to cookies, which are stored on a
            computer’s hard drive, clear GIFs are embedded invisibly on web
            pages. We may use clear GIFs (also referred to as web beacons, web
            bugs, or pixel tags) in connection with our Services to, among other
            things, track user activities, help us manage content, and compile
            statistics about usage of our Services.
          </p>
          <p style={contentStyle}>
            We and our service providers also use clear GIFs in HTML emails to
            you, to help us track email response rates, identify when our emails
            are viewed, and track whether our emails are forwarded.
          </p>
        </div>
        <span style={spanStyle}>USER GENERATED CONTENT</span>
        <p style={contentStyle}>
          We invite registered users to post content on our Site, including your
          comments, pictures, and any other information that you would like to
          be available on our Site. If you post content to our Site, all of the
          information that you post will be available to our registered
          customers. If you post your own content on our Site or services, your
          posting may become public and we cannot prevent such information from
          being used in a manner that may violate this Policy, the law, or your
          personal privacy.
        </p>

        <span style={spanStyle}>
          Does Epic-X Bharat Private Limited Disclose any information to third
          parties?
        </span>
        <p style={contentStyle}>
          Epic-X Bharat Private Limited does not sell, trade or otherwise
          transfer to outside parties any personally identifiable information.
          This does not include trusted third parties or subcontractors who
          assist us in operating our website, conducting our business, or
          servicing you. Such trusted parties may have access to personally
          identifiable information on a need-to-know basis and will be
          contractually obliged to keep your information confidential.
        </p>

        <p style={contentStyle}>
          In the context of an onward transfer, Epic-X Bharat Private Limited,
          as a Privacy Shield organization, has responsibility for the
          processing of personal information it receives under the Privacy
          Shield and subsequently transfers to a third party acting as an agent
          on its behalf. Epic-X Bharat Private Limited, as a Privacy Shield
          organization, shall remain liable under the Principles if its agent
          processes such personal information in a manner inconsistent with the
          Principles, unless the organization proves that it is not responsible
          for the event giving rise to the damage.
        </p>

        <p style={contentStyle}>
          We may also release your information when we believe release is
          appropriate to comply with the law, enforce our site policies, or
          protect our or others’ rights, property, or safety. Furthermore,
          non-personally identifiable visitor information may be provided to
          other parties for marketing, advertising, or other uses.
        </p>

        <span style={spanStyle}>Subcontractors/Trusted Third Parties</span>
        <p style={contentStyle}>
          The subcontractors Amazon.com, Inc. is certified under the EU-US
          Privacy Shield and AWS is covered under this certification. This helps
          customers who choose to transfer personal data to the US to meet their
          data protection obligations. Amazon.com Inc.’s certification can be
          found on the EU-US Privacy Shield website here:{" "}
          <a
            href="https://www.privacyshield.gov/list"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.privacyshield.gov/list
          </a>
        </p>

        <p style={contentStyle}>
          Any intended changes concerning the addition or replacement of
          subcontractors or subprocessors handling personal data will be
          announced to you with at least 3 months’ notice. You retain at all
          times the possibility to object to such changes or to terminate the
          contract with Epic-X Bharat Private Limited.
        </p>

        <span style={spanStyle}>Third-Party Links & Analytics</span>
        <p style={contentStyle}>
          We may use third-party analytics companies, such as Google Analytics,
          to evaluate use of our Services. We or our service providers use these
          tools to help us understand use of, and to improve, our Services,
          performance, and user experiences. These entities may use cookies and
          other tracking technologies, such as web beacons or local storage
          objects (“LSOs”), to perform their services. To learn more about
          Google’s privacy practices, please see the{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Privacy Policy
          </a>
          ; to opt out of being tracked by Google Analytics, you can download
          the{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Analytics Opt-out Browser Add-on
          </a>
          . We are not responsible for the information practices of such third
          party websites.
        </p>

        <span style={spanStyle}>Do Not Track Disclosure</span>
        <p style={contentStyle}>
          Our Services do not respond to Do Not Track signals. For more
          information about Do Not Track signals, please click here. You may,
          however, disable certain tracking as discussed in the Cookies and
          Other Tracking Mechanisms section above (e.g., by disabling cookies).
        </p>

        <span style={spanStyle}>Interest-Based Advertising</span>
        <p style={contentStyle}>
          We use third parties such as network advertisers to display
          advertisements on our Services, to assist us in displaying
          advertisements on third-party services, and to evaluate the success of
          advertising campaigns. You may opt out of many third-party ad
          networks, including those operated by members of the Network
          Advertising Initiative (“NAI”) and the Digital Advertising Alliance
          (“DAA”). For more information regarding this practice by NAI members
          and DAA members, and your choices regarding having this information
          used by these companies, including how to opt out of third-party ad
          networks operated by NAI and DAA members, please visit their
          respective websites:{" "}
          <a
            href="http://www.networkadvertising.org/optout_nonppii.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.networkadvertising.org/optout_nonppii.asp (NAI)
          </a>{" "}
          and{" "}
          <a
            href="http://www.aboutads.info/choices"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.aboutads.info/choices(DAA)
          </a>
          .
        </p>

        <p style={contentStyle}>
          Opting out of one or more NAI member or DAA member networks (many of
          which will be the same) only means that those members no longer will
          deliver targeted content or ads to you. It does not mean you will no
          longer receive any targeted content or ads on our Services or other
          services. You may continue to receive advertisements, for example,
          based on the particular website that you are viewing (i.e., contextual
          advertising). Also, if your browsers are configured to reject cookies
          when you visit an opt-out page, or you subsequently erase your
          cookies, use a different computer or change web browsers, your NAI or
          DAA opt out may no longer be effective.
        </p>

        <span style={spanStyle}>Third-Party Collection</span>
        <p style={contentStyle}>
          Our Services may contain links to third-party websites and
          applications or integrate with services provided by third parties. Any
          access to and use of such linked websites, applications, or
          third-party services is not governed by this Privacy Policy but
          instead is governed by the privacy policies of those third parties. We
          are not responsible for the information practices of such third
          parties, and we encourage you to read their privacy policies.
        </p>

        <p style={contentStyle}>
          Epic-X Bharat Private Limited use and transfer to any other app of
          information received from Google APIs will adhere to Google API
          Services User Data Policy, including the Limited Use requirements.
        </p>

        <span style={spanStyle}>ACCESS</span>
        <p style={contentStyle}>
          If you’d like us to provide access to, update, correct, or delete any
          personally identifiable information that you have provided on the Site
          or that we may have in connection with the Analytics Services, please
          send your request to{" "}
          <a href="mailto:support@epic-x.io">support@epic-x.io</a> and the
          company will process your request within a reasonable period of time
          after receipt. EU data subjects have additional rights listed below.
        </p>

        <span style={spanStyle}>DATA RETENTION</span>
        <p style={contentStyle}>
          We store personally identifiable information such as email address or
          billing details received from our Clients so long as they continue to
          have a business relationship with Epic-X Bharat Private Limited. You
          may ask us to delete that information by following the instructions
          above. As an agent (i.e., data processor) of our Clients, store
          information collected via our Marketing and Analytics Services for a
          reasonable time as directed by our Clients.
        </p>

        <span style={spanStyle}>Data retention policy</span>
        <ul>
          <li style={contentStyle}>
            Account Data will due to tax regulations be retained for up to five
            full fiscal years from your cancellation of your Service account.
          </li>
          <li style={contentStyle}>
            Configuration Data and System Generated Data will be erased
            immediately when you cancel the Service account.
          </li>
          <li style={contentStyle}>
            End User Data will be erased on an ongoing basis after 12 months
            from registration, and immediately when you cancel the Service
            account.
          </li>
        </ul>

        <span style={spanStyle}>Data restitution and/or deletion</span>
        <p style={contentStyle}>
          No data except Account Data will be retained after the termination of
          the contract. You may request a data copy before termination. You must
          not cancel the Service account until the data copy has been delivered,
          as Epic-X Bharat Private Limited otherwise will not be able to deliver
          the data copy.
        </p>

        <span style={spanStyle}>DATA INTEGRITY, PURPOSE LIMITATION</span>
        <p style={contentStyle}>
          We process information in a way that is compatible with and relevant
          for the purpose for which it was collected as described above. To the
          extent necessary for those purposes, we take reasonable steps to
          ensure that any information in our care is accurate, complete, current
          and reliable for its intended use.
        </p>

        <span style={spanStyle}>SECURITY</span>
        <p style={contentStyle}>
          We have implemented reasonable security precautions to protect the
          information we collect from loss, misuse, and unauthorized access,
          disclosure, alteration, and destruction. Please be aware that despite
          our best efforts, no data security measures can guarantee 100%
          security. You should take steps to protect against unauthorized access
          to your password, phone, and computer by, among other things, signing
          off after using a shared computer, choosing a robust password that
          nobody else knows or can easily guess, and keeping your log-in and
          password private. We are not responsible for any lost, stolen, or
          compromised passwords or for any activity on your account via
          unauthorized password activity.
        </p>

        <span style={spanStyle}>MARKETING COMMUNICATIONS</span>
        <p style={contentStyle}>
          We may send periodic promotional emails to you. You may opt-out of
          such communications by following the opt-out instructions contained in
          the e-mail. Please note that it may take up to 10 business days for us
          to process opt-out requests. If you opt-out of receiving emails about
          recommendations or other information we think may interest you, we may
          still send you e-mails related to your account or any services you
          have requested or received from us.
        </p>

        <span style={spanStyle}>CAN-SPAM Act</span>
        <p style={contentStyle}>
          The CAN-SPAM Act is a law that sets the rules for commercial email,
          establishes requirements for commercial messages, gives recipients the
          right to have emails stopped from being sent to them, and spells out
          tough penalties for violations.
        </p>
        <p style={contentStyle}>We collect your email address in order to:</p>
        <ul>
          <li style={contentStyle}>
            Send information, respond to inquiries, and/or other requests or
            questions.
          </li>
          <li style={contentStyle}>
            Process orders and to send information and updates pertaining to
            orders.
          </li>
          <li style={contentStyle}>
            Send you additional information related to your product and/or
            service.
          </li>
          <li style={contentStyle}>
            Market to our mailing list or continue to send emails to our clients
            after the original transaction has occurred.
          </li>
        </ul>

        <h4>To be in accordance with CANSPAM we agree to the following:</h4>
        <ul>
          <li style={contentStyle}>NOT use false, or misleading subjects or email addresses.</li>
          <li style={contentStyle}>
            Identify the message as an advertisement in some reasonable way.
          </li>
          <li style={contentStyle}>
            Monitor third party email marketing services for compliance, if one
            is used.
          </li>
          <li style={contentStyle}>Honor opt-out/unsubscribe requests quickly.</li>
          <li style={contentStyle}>
            Allow users to unsubscribe by using the link at the bottom of each
            email.
          </li>
        </ul>

        <p style={contentStyle}>
          If at any time you would like to unsubscribe from receiving future
          emails, follow the unsubscribe instructions at the bottom of each
          email and we will promptly remove you from ALL correspondence.
        </p>

        <span style={spanStyle}>Your Choices</span>

        <h4>Updating or Deleting Personal Information</h4>
        <p style={contentStyle}>
          Visitors and Customers can at any time update their cookie preferences
          by visiting our website and clicking on the cookie declaration in the
          footer. Once there they simply need to click the “change your consent”
          hyperlink. Visitors and Customers may correct or delete the personal
          information that they provide to us by emailing us at{" "}
          <a href="mailto:techsupport@epic-x.io">techsupport@epic-x.io</a>{" "}
          describing their request to update or delete their personal
          information. Please note that we may retain certain information about
          them as required by law or as permitted by law for legitimate business
          purposes (e.g., in accordance with our record retention policies or to
          enforce our Terms of Service). End Users who wish to correct or delete
          the personal data that we use on behalf of Customers should contact
          the Customer with which they interact to make such a request.
        </p>

        <h4>Opting out of Email or Text Communications</h4>
        <p style={contentStyle}>
          We may send periodic promotional or informational emails to Visitors
          and Customers. Visitors and Customers may opt out of such
          communications by following the opt-out instructions contained in the
          email or contacting us at the Contact Us information below. Please
          note that it may take up to 10 business days for us to process opt-out
          requests. If Visitors or Customers opt out of receiving marketing
          emails from us, we may still send them non-promotional emails (e.g.,
          about their account with us). If you are an End User that receives
          emails from one of our Customers and you no longer want to be
          contacted by that Customer, please unsubscribe directly from that
          Customer’s communication or contact the Customer directly to update
          your email preferences.
        </p>

        <span style={spanStyle}>Children</span>
        <p style={contentStyle}>
          Our Services are not targeted to children under thirteen (13) years of
          age, and we do not knowingly collect personal information from
          children under 13. If we discover that a child under 13 has provided
          us with personal information, we will promptly delete such personal
          information from our systems.
        </p>

        <span style={spanStyle}>Dispute Resolution</span>
        <p style={contentStyle}>
          Within the scope of this privacy notice, if a privacy complaint or
          dispute cannot be resolved through Epic-X Bharat Private Limited's
          internal processes, Epic-X Bharat Private Limited has designated it
          will work with EU Data Protection Authorities (DPA). The DPA (Data
          Protection Authority) is the agency within each European Union country
          that is responsible for GDPR (General Data Protection Regulation)
          assistance and EUROPEAN FREE TRADE AREA (EFTA).
        </p>

        <span style={spanStyle}>Terms of Service</span>

        <h4>Your Consent</h4>
        <p style={contentStyle}>By using our site, you consent to this Privacy Policy.</p>

        <span style={spanStyle}>Contact Us</span>
        <p style={contentStyle}>
          If you have questions about the privacy aspects of our Services or for
          further information about our data privacy policies and practices,
          please contact us at{" "}
          <a href="mailto:techsupport@epic-x.io">techsupport@epic-x.io</a> or
          +91 9080397303.
        </p>

        <span style={spanStyle}>EU DATA SUBJECTs</span>
        <h4>EU General Data Protection Regulation (GDPR)</h4>
        <p style={contentStyle}>
          The processing of your data is either based on your consent or in case
          the processing is necessary for the performance of a contract to which
          you are a party, or in order to take steps at your request prior to
          entering into a contract, cf. GDPR art. 6(1)(a)-(b).
        </p>
        <p style={contentStyle}>
          If the processing is based on your consent, you may at any time
          withdraw your consent by contacting us using the contact information
          in clause 1.
        </p>
        <p style={contentStyle}>
          In order to enter into a contract regarding the purchase of Epic-X
          Bharat Private Limited’s Service, you must provide us with the
          required personal data. If you do not provide us with all the required
          information, it will not be possible to deliver the Service.
        </p>
        <p style={contentStyle}>
          In addition to the above referenced privacy practices, EU Data
          subjects are granted additional privacy rights under the General Data
          Privacy Regulation (“GDPR”). For example, an EU individual who seeks
          access, or who seeks to correct, amend, port over and/or delete
          inaccurate data, or who wishes to limit the use and disclosure of
          their personal data should visit our EU Subject Access Page. Those
          rights also include the right to complain to EU Supervisory
          Authorities.
        </p>
        <p style={contentStyle}>
          With respect to EU data subjects, personal data includes Pseudonymous
          Data such as an IP address, a mobile advertising ID or a cookie ID as
          well as PII. EU Data subject wishing to object to Epic-X Bharat
          Private Limited’ processing of data using the opt-out methods
          described above should note that the presence of an Epic-X Bharat
          Private Limited opt-out cookie tells our systems that you object to
          our processing of data.
        </p>
        <p style={contentStyle}>
          Epic-X Bharat Private Limited operates the Platform and provides the
          Services as a “data processor” under the GDPR. That means that Epic-X
          Bharat Private Limited processes data via the Platform and Services as
          directed by each of our Clients pursuant to our contracts with
          Clients. While Epic-X Bharat Private Limited does enable Clients to
          create profiles of Internet Users, none of those profiles contain
          sensitive information, nor do the profiles present a “high risk” to
          the fundamental human rights of EU data subjects.
        </p>
        <p style={contentStyle}>
          We process certain EU personal data provided by Clients and Data
          Partners under contractual necessity. For example, we require the
          billing details of our Clients located in the EU to process payment
          for the Services and we need email addresses from Clients to enable
          them to login to our systems. We collect contact details from EU data
          subjects via our Website with consent and ask for consent to place
          cookies onto EU desktops. Our sales and marketing team may obtain
          additional contact details for current and prospective Clients via our
          legitimate interest.
        </p>

        <span style={spanStyle}>Data Protection Officer</span>
        <p style={contentStyle}>
          Epic-X Bharat Private Limited’ data protection officer is{" "}
          <strong>Vijayan Gokul</strong>, Director of Epic-X Bharat Private
          Limited.
        </p>
        <p style={contentStyle}>
          <a href="mailto:techsupport@epic-x.io">techsupport@epic-x.io</a>
        </p>

        {/* ---------- */}

        <span style={spanStyle}>Recourse, Privacy Complaints by EU Residents</span>
    <p style={contentStyle}>Epic-X Bharat Private Limited complies with the EU-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union to the United States. Epic-X Bharat Private Limited has certified to the Department of Commerce that it adheres to the Privacy Shield Principles. If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a></p>
    <p style={contentStyle}>In compliance with the EU-US Privacy Shield Principles, Epic-X Bharat Private Limited commits to resolve complaints about your privacy and our collection or use of your personal information. European Union individuals with inquiries or complaints regarding this privacy policy should first contact Epic-X Bharat Private Limited via email at: <a href="mailto:techsupport@epic-x.io">techsupport@epic-x.io</a></p>
    <p style={contentStyle}>Under certain conditions – available for the User in full on the Privacy Shield website – the User may invoke binding arbitration when other dispute resolution procedures have been exhausted.</p>

    <span style={spanStyle}>Information We Collect</span>
    <p style={contentStyle}>Our Website collects information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information“). In particular, Epic-X Bharat Private Limited’s website, www.Epic-X Bharat Private Limited.com (“Website”), has collected the following categories of personal information from its consumers within the last twelve (12) months:</p>
    <p style={contentStyle}><strong>Personal information does not include:</strong></p>
    <ul>
        <li style={contentStyle}>Publicly available information from government records.</li>
        <li style={contentStyle}>Deidentified or aggregated consumer information.</li>
        <li style={contentStyle}>Health or medical information covered by the Health Insurance Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
        <li style={contentStyle}>Personal information covered by certain sector-specific privacy laws.</li>
    </ul>
    <p style={contentStyle}>Epic-X Bharat Private Limited obtains the categories of personal information listed above from the following categories of sources:</p>
    <ul>
        <li style={contentStyle}>Directly from you. For example, from forms you complete or products and services you purchase.</li>
        <li style={contentStyle}>Indirectly from you. For example, from observing your actions on our Website.</li>
    </ul>

    <span style={spanStyle}>Use of Personal Information</span>
    <p style={contentStyle}>We may use, or disclose the personal information we collect for one or more of the following business purposes:</p>
    <ul>
        <li style={contentStyle}>To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a price quote or ask a question about our products or services, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate delivery. We may also save your information to facilitate new product orders or process returns.</li>
        <li style={contentStyle}>To provide, support, personalize, and develop our Website, products, and services.</li>
        <li style={contentStyle}>To create, maintain, customize, and secure your account with us.</li>
        <li style={contentStyle}>To process your requests, purchases, transactions, and payments and prevent transactional fraud.</li>
        <li style={contentStyle}>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</li>
        <li style={contentStyle}>To personalize your Website experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Website, third-party sites, and via email or text message (with your consent, where required by law).</li>
        <li style={contentStyle}>To help maintain the safety, security, and integrity of our Website, products and services, databases and other technology assets, and business.</li>
        <li style={contentStyle}>For testing, research, analysis, and product development, including to develop and improve our Website, products, and services.</li>
        <li style={contentStyle}>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
        <li style={contentStyle}>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Epic-X Bharat Private Limited’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Epic-X Bharat Private Limited about our Website users is among the assets transferred.</li>
    </ul>
    <p style={contentStyle}>Epic-X Bharat Private Limited will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>

    <span style={spanStyle}>Sharing Personal Information</span>
    <p style={contentStyle}>Epic-X Bharat Private Limited may disclose your personal information to a third party for a business purpose. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract. (See our third-party privacy addendum)</p>
    <p style={contentStyle}>We share your personal information with the following categories of third parties:</p>
    <ul>
        <li style={contentStyle}>Service providers.</li>
        <li style={contentStyle}>Data aggregators.</li>
    </ul>

    <span style={spanStyle}>Disclosures of Personal Information for a Business Purpose</span>
    <p style={contentStyle}>In the preceding twelve (12) months, the Company has disclosed the following categories of personal information for a business purpose:</p>
    <ul>
        <li style={contentStyle}>[Category A: Identifiers.]</li>
        <li style={contentStyle}>[Category B: Commercial information.]</li>
        <li style={contentStyle}>[Category C: Internet or other similar network activity.]</li>
        <li style={contentStyle}>[Category D: Geolocation data.]</li>
        <li style={contentStyle}>[Category E: Inferences drawn from other personal information.]</li>
    </ul>
    <p style={contentStyle}>We disclose your personal information for a business purpose to the following categories of third parties:</p>
    <ul>
        <li style={contentStyle}>Service providers.</li>
        <li style={contentStyle}>Data aggregators.</li>
    </ul>

    <span style={spanStyle}>Sales of Personal Information</span>
    <p style={contentStyle}>In the preceding twelve (12) months, Company has not sold personal information.</p>

    <span style={spanStyle}>Your Rights and Choices</span>
    <h4>Access to Specific Information and Data Portability Rights</h4>
    <p style={contentStyle}>You have the right to request that Epic-X Bharat Private Limited disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request (see Exercising Access, Data Portability, and Deletion Rights), we will disclose to you:</p>
    <ul>
        <li style={contentStyle}>The categories of personal information we collected about you.</li>
        <li style={contentStyle}>The categories of sources for the personal information we collected about you.</li>
        <li style={contentStyle}>Our business or commercial purpose for collecting or selling that personal information.</li>
        <li style={contentStyle}>The categories of third parties with whom we share that personal information.</li>
        <li style={contentStyle}>The specific pieces of personal information we collected about you (also called a data portability request).</li>
    </ul>
    <p style={contentStyle}>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</p>
    <ul>
        <li style={contentStyle}>Sales, identifying the personal information categories that each category of recipient purchased;</li>
        <li style={contentStyle}>Disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
    </ul>

    <span style={spanStyle}>Deletion Request Rights</span>
    <p style={contentStyle}>You have the right to request that Epic-X Bharat Private Limited delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request (see Exercising Access, Data Portability, and Deletion Rights), we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
    
    <p style={contentStyle}>We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:</p>
    <ol>
        <li style={{ listStyleType: 'decimal' }}>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
        <li style={{ listStyleType: 'decimal' }}>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
        <li style={{ listStyleType: 'decimal' }}>Debug products to identify and repair errors that impair existing intended functionality.</li>
        <li style={{ listStyleType: 'decimal' }}>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
        <li style={{ listStyleType: 'decimal' }}>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent.</li>
        <li style={{ listStyleType: 'decimal' }}>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
        <li style={{ listStyleType: 'decimal' }}>Comply with a legal obligation.</li>
        <li style={{ listStyleType: 'decimal' }}>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
    </ol>
    <div>
      <span style={spanStyle}>Exercising Access, Data Portability, and Deletion Rights</span>
      <p style={contentStyle}>
        To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by either:
      </p>
      <ol>
        <li style={contentStyle}>Calling us at +91 9080397303</li>
        <li style={contentStyle}>Emailing us at techsupport@epic-x.io</li>
      </ol>
      <p style={contentStyle}>
        You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:
      </p>
      <ol>
        <li style={contentStyle}>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
        <li style={contentStyle}>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
      </ol>
      <p style={contentStyle}>
        We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.
      </p>
      <p style={contentStyle}>
        Making a verifiable consumer request does not require you to create an account with us. However, we do consider requests made through your password protected account sufficiently verified when the request relates to personal information associated with that specific account.
      </p>
      <p style={contentStyle}>
        We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.
      </p>
      <span style={spanStyle}>Response Timing and Format</span>
      <p style={contentStyle}>
        We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.
      </p>
      <p style={contentStyle}>
        If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.
      </p>
      <p style={contentStyle}>
        Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily usable and should allow you to transmit the information from one entity to another entity without hindrance.
      </p>
      <p style={contentStyle}>
        We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
      </p>
      <h4>FAQ</h4>
      <p style={contentStyle}>If you have questions about Privacy Shield, frequently asked questions can be found here:</p>
      <a href="https://www.privacyshield.gov/article?id=Privacy-Policy-FAQs-1-5">Privacy Policy FAQs</a>
    </div>

    <div>
      <span style={spanStyle}>Contact Information</span>
      <p style={contentStyle}>
        If you have any questions or comments about this notice, the ways in which Epic-X Bharat Private Limited collects and uses your information described below and in the Privacy Policy, your choices and rights regarding such use please do not hesitate to contact us at:
      </p>
      <ol>
        <li style={contentStyle}>Phone: +91 9080397303</li>
        <li style={contentStyle}>Website: <a href="http://www.epic-x.io" target="_blank" rel="noopener noreferrer">www.epic-x.io</a></li>
        <li style={contentStyle}>Email: <a href="mailto:techsupport@epic-x.io">techsupport@epic-x.io</a></li>
        <li style={contentStyle}>
          Postal Address:
          <ul>
            <li style={contentStyle}>Epic-X Bharat Private Limited</li>
            <li style={contentStyle}>Address: 1274, RSB Towers, </li>
            <li style={contentStyle}>Above Lakshmi Wet Grinder, Mettupalayam Rd,</li>
            <li style={contentStyle}>Near Saibaba Temple. Coimbatore,</li>
            <li style={contentStyle}> Tamil Nadu 641043</li>
          </ul>
        </li>
      </ol>
      
      <span style={spanStyle}>CHANGES TO THIS POLICY</span>
      <p style={contentStyle}>
        This Policy is current as of the Effective Date set forth above. We may change this Policy from time to time, so please be sure to check back periodically. We will post any changes to this Policy on our Site. Epic-X Bharat Private Limited reserves the right to amend this privacy notice at our discretion and at any time.
      </p>
      <p style={contentStyle}>
        When we make changes to this privacy notice, we will post the updated notice on the Website and update the notice’s effective date. Your continued use of our Website following the posting of changes constitutes your acceptance of such changes. We always indicate the date the last changes were published and we offer access to archived versions for your review.
      </p>
    </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
