import React, { createContext, useState, useEffect } from 'react';

export const ContextProvider = createContext();

const ValueProvider = ({ children }) => {
  const [tenant, setTenant] = useState(null);
  const [productSelected, setProductSelected] = useState(() => {
    return localStorage.getItem('productSelected') || '';
  });
  const [planSelected, setPlanSelected] = useState(() => {
    return localStorage.getItem('planSelected') || '';
  });
  const [planTypeSelected, setPlanTypeSelected] = useState(() => {
    return localStorage.getItem('planTypeSelected') || '';
  });
  const [ userEmail, setUserEmail ] = useState('')

  useEffect(() => {
    localStorage.setItem('productSelected', productSelected);
  }, [productSelected]);

  useEffect(() => {
    localStorage.setItem('planSelected', planSelected);
  }, [planSelected]);

  useEffect(() => {
    localStorage.setItem('planTypeSelected', planTypeSelected);
  }, [planTypeSelected]);

  return (
    <ContextProvider.Provider
      value={{
        tenant,
        setTenant,
        productSelected,
        setProductSelected,
        planSelected,
        setPlanSelected,
        planTypeSelected,
        setPlanTypeSelected,
        userEmail, setUserEmail
      }}
    >
      {children}
    </ContextProvider.Provider>
  );
};

export default ValueProvider;
