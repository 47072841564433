import React, { useEffect, useState } from 'react'
import axios from "axios"
import { useNavigate } from 'react-router-dom';

const Subscribe = () => {
  const navigate = useNavigate();

  const [storeName,setStoreName]=useState('')
  const [token,setToken]=useState('')
  useEffect(()=>{
      const url = window.location.href;
      
      const currentUrl = new URL(url);
      
      const decode = currentUrl.searchParams.get('token');
      setToken(decode)
      const data=atob(decode)
      const storename=data.split(';')
      setStoreName(storename[1])
  },[])

  const handleunsubscribe=async ()=>{
      try{
        const response=await axios.put(`http://localhost:3000/api/unsubscribe?token=${token}`)
        if(response.status===200){
              navigate(`/api/unsubscribe?token=${token}`)
        }
      }catch(error){
        console.error('error updating Unsubscribe',error);
      }
  }

  return (
    <div style={{display:'flex',flexDirection:'column',gap:'10px',margin:'40px 400px'}}>
      <h1>{storeName}</h1>
      <h1 style={{fontSize:'20px',fontWeight:'bold'}}>Thank you for subscribing to our mailing list.</h1>
        <p>You can unsubscribe at any time.</p>
        <p style={{ backgroundColor: "blue", color: "white",width:'180px', padding:"10px 20px", textAlign: "center",cursor:'pointer' }}
        onClick={handleunsubscribe}>
          Unsubscribe
      </p>
    </div>
  )
}
export default Subscribe
