import React, { useEffect, useRef } from 'react'
import { Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import Je_ecom from './assets/img/Je_ecom.png'
import Mobi_dev from './assets/img/Mobi_dev.png'
import ico_01 from './assets/img/ico_01.png'
import ico_02 from './assets/img/ico_02.png'
import ico_03 from './assets/img/ico_03.png'
import ico_04 from './assets/img/ico_04.png'
import ico_05 from './assets/img/ico_05.png'
import mob_pic from './assets/img/mob_pic.png'
import Dark_int from './assets/img/Dark_int.png'
import Light_int from './assets/img/Light_int.png'
import store  from './assets/img/store.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import  course_graphics from "./assets/img/course-graphics.svg"
import testimonials_1 from './assets/img/testimonials/testimonials-1.png'
import testimonials_2 from './assets/img/testimonials/testimonials-2.png'
import testimonials_3 from './assets/img/testimonials/testimonials-3.jpg'
import Business_People_analysis from './assets/img/Business_People_analysis.svg';


function MainContent() {

  return (
    <main id="main" style={{marginTop:'60px'}}>
      {/* ================ Hero Section ============= */}
      <section id="hero" class="hero d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column justify-content-center">
            <h1 class="epic-heading">Prepare your business for the market.</h1>
            <h3 class="epic-heading" data-aos="fade-up">Enhance your business's reach through tailored  Ecommerce development solutions</h3>
            <p data-aos="fade-up" data-aos-delay="400">Enhance your business's outreach by utilizing our  E-commerce Development Services. Craft stunning web stores that captivate your desired audience with their aesthetics and functionality. Create  E-commerce storefronts that feature enticing customer interfaces, seamless integrations, and enhanced user experiences. </p>
            <div data-aos="fade-up" data-aos-delay="600">
              <div class="text-center text-lg-start">
                <a href="#about" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Get Started</span>
                  <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
            <img src={Business_People_analysis} className="img-fluid" alt="enhance Your Business" />
          </div>
        </div>
      </div>
    </section>
       {/* <!-- ======= About Section ======= --> */}
            <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="row gx-0">
          <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div className="content">
              <h3 className="section-heading">Empowering Businesses to Establish a Remarkable Online Presence</h3>
              <p> Bid farewell to waiting in lines, rushing through stores, and worrying about stock shortages! E-commerce has simplified people's lives by enabling them to shop conveniently at any time and from anywhere. Our mission is to facilitate your business's expansion into the online realm through our dependable, dedicated, and expert  E-commerce development solutions. Our endeavors have assisted organizations in delivering convenience, engagement, and exceptional user experiences. We foster a team of seasoned industry experts, digital marketers, developers, and quality professionals who can provide you with mobile and web  E-commerce development services to stimulate customer loyalty, enhance satisfaction, boost sales, and contribute to business growth. </p>
              <div className="text-center text-lg-start">
                <a href="#hero" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
            <img src={Je_ecom} className="img-fluid w-70 mr-auto" alt="Jewllery  E-commerce" />
          </div>
        </div>
      </div>
    </section>
    {/* <!-- End About Section -->
      <!--Vision and Mission Start here--> */}
     <section id="vismis">
        <div class="who-we-area-area bg-gray-200">
          <div class="container">
            <div class="who-we-area-box">
              <div class="row">
                <div class="col-lg-5 thumb bg-cover"></div>
                <div class="col-lg-7 info">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 item">
                      <div class="aligns">
                        <h4 class="pl-0 mr-2 section-heading fs-35 text-blue font-semibold">Our Mission</h4>
                        <span class="mb-2 line"></span>
                      </div>
                      <p> Revolutionize the way people interact with technology by harnessing the power of Artificial Intelligence to create cutting-edge products that deliver autonomous and effortless experiences. We are dedicated to enhancing the quality of life for users through intelligent, user-centric solutions that cater to their unique needs, while redefining customer satisfaction with unparalleled service.</p>
                      <a href="#hero" class="btn-learn">Know More </a>
                    </div>
                    <div class="col-lg-6 col-md-6 item">
                      <div class="aligns">
                        <h4 class="pl-0 mr-2 section-heading fs-35 text-blue font-semibold">Our Vision</h4>
                        <span class="mb-2 line"></span>
                      </div>
                      <p> Our vision is to make AI an indispensable ally in users' lives. We'll achieve this by researching, developing, and integrating advanced AI technologies, with a focus on autonomy, intelligence, and user experience. Our goal is to create products that anticipate and enhance users' lives.</p>
                      <a href="#hero" class="btn-learn">Know More </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container mt-70" data-aos="fade-up">
            <div class="row gx-0">
              <div class="col-lg-6 d-flex flex-column justify-content-center order-1" data-aos="fade-up" data-aos-delay="200">
                <div class="content">
                  <h3 class="section-heading">Catalyze Your E-commerce Initiatives with Mobile App Development Services</h3>
                  <p> Our mobile app development services harness cutting-edge technologies and emerging trends to energize your e-commerce endeavors. A mobile application has the potential to expedite your business operations and enhance your return on investment.EPIC-X offers top-tier iOS and Android e-commerce mobile apps, empowering your business to leverage the booming mobile app market. Amplify your business reach with these exceptional mobile apps!. </p>
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center order-2" data-aos="zoom-out" data-aos-delay="200">
                <img src={Mobi_dev} className="img-fluid w-60 mr-auto" alt="Mobile App Development"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---Vision Mission End Here--> */}
      {/* <!-- ======= About Section ======= --> */}
      <section id="about" className="about bg-gray-200">
        <div className="container" data-aos="fade-up">
          <div className="row text-center">
            <div className="col">
              <img src={ico_01} alt="Shopping Cart"/>
              <h5>Custom Shopping Cart</h5>
            </div>
            <div className="col">
              <img src={ico_02} alt="Admin Panel"/>
              <h5>Custom Admin Panel</h5>
            </div>
            <div className="col">
              <img src={ico_03} alt="Micro Interactions"/>
              <h5>Micro Interactions</h5>
            </div>
            <div className="col">
              <img src={ico_04} alt="Payment Gateway"/>
              <h5>Secure Payment Gateway</h5>
            </div>
            <div className="col">
              <img src={ico_05} alt="Social Media"/>
              <h5>Social Media Integration</h5>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}
      {/* <!-- ======= About Section ======= --> */}
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gx-0">
            <div className="col-lg-6 d-flex flex-column justify-content-center order-2" data-aos="fade-up" data-aos-delay="200">
              <div className="content">
                <h3 className="section-heading text-blue">Expand Your Business Worldwide with a Simple Click</h3>
                <p> Introduce your products and services to a global audience with just a single click. Leave behind the constraints of geography and broaden your market reach through a robust online presence facilitated by our  E-commerce development services. Our dedicated merchant panel empowers you to efficiently handle order requests, order-related data, and all aspects of your  E-commerce storefronts. Achieve swift sales, enhance the customer experience, and secure more successful transactions with just a few clicks! </p>
                <div className="text-center text-lg-start">
                  <a href="#hero" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center order-1" data-aos="zoom-out" data-aos-delay="200">
              <img src={mob_pic} className="img-fluid w-70 mr-auto" alt="Expand Business"/>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}
      {/* <!-- =======  E-commerce Servics Section ======= --> */}
      <section id="ecom-serv" class="service">
        <div class="container" data-aos="fade-up">
          <div className="row">
            <div class="col-6 col-12">
              <h2 class="section-heading text-blue font-semibold">Our Services for  E-commerce Development.</h2>
            </div>
            <div class="col-6 col-12">
              <p>Generate quick sales for your website with highly user-centric design and great functionality. We provide strategic consulting, leading design, and digital marketing services to level up your  E-commerce stores. Through the integration of WooCommerce, Shopify, and Magento 2, we implement the design and construction of  E-commerce websites on all major platforms. </p>
            </div>
          </div>
          <div className="d-flex row"style={{flexWrap:'wrap',justifyContent:'center'}}>
            <div class="col-3 aos-init aos-animate" data-aos="flip-left" data-aos-duration="1000"style={{width:'285px'}}>
              <div class="process">
                <svg width="57" height="66" viewBox="0 0 57 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_150_20)">
                    <path d="M27.8754 65.1704C26.7444 64.9141 25.6576 64.5613 24.7837 63.7434C23.1053 62.1723 22.4649 60.2564 23.0706 58.0272C23.7031 55.6983 25.2695 54.2776 27.6514 53.9106C30.281 53.5055 32.4989 54.7538 33.6378 57.1855C33.7892 57.5099 33.9627 57.6 34.2987 57.5969C36.1238 57.581 37.9504 57.6079 39.7755 57.5842C42.3562 57.551 44.6245 55.866 45.1672 53.5514C44.0472 53.8852 42.9572 53.8457 41.8782 53.5024C39.5705 52.7683 37.9883 50.6783 37.9678 48.1153C37.9268 42.9798 37.8952 37.8443 37.9741 32.7103C38.0435 28.1744 42.7032 25.5908 46.579 27.8944C48.3788 28.9639 49.2669 30.6583 49.2764 32.7341C49.3048 39.2159 49.3395 45.6994 49.2637 52.1797C49.2054 57.176 45.1009 61.2373 40.1194 61.3623C38.1902 61.4113 36.2579 61.3654 34.3287 61.3923C34.111 61.3955 33.7797 61.5395 33.7009 61.7103C32.8475 63.5361 31.4641 64.6626 29.4939 65.0692C29.4134 65.085 29.3409 65.134 29.2636 65.1673C28.7998 65.1704 28.3376 65.1704 27.8754 65.1704Z" fill="white" />
                    <path d="M0.191757 28.7174C0.11604 13.8567 12.3174 0.508418 28.505 0.506836C44.6783 0.505254 56.8923 13.8361 56.8198 28.893C56.3844 28.6367 55.9995 28.3598 55.5768 28.17C54.8717 27.8551 54.1524 27.5625 53.4173 27.3267C53.0813 27.2191 52.9535 27.0878 52.922 26.7334C52.5923 23.0993 51.5322 19.6931 49.6898 16.5447C47.1927 12.2746 43.7319 9.02332 39.3103 6.82577C35.1491 4.75796 30.7339 3.97481 26.1199 4.41622C22.1274 4.79751 18.4331 6.09958 15.0858 8.32403C10.688 11.2462 7.52994 15.1904 5.60706 20.1171C4.77733 22.2434 4.30411 24.4584 4.07065 26.7287C4.05172 26.917 3.92079 27.1875 3.76936 27.2524C2.58944 27.7634 1.39218 28.2333 0.191757 28.7174Z" fill="url(#paint0_radial_150_20)" />
                    <path d="M7.72324 40.3477C7.72324 37.8638 7.71062 35.3815 7.7264 32.8976C7.74217 30.3156 9.25019 28.1133 11.468 27.3839C15.2775 26.1325 18.9734 28.6559 19.035 32.6776C19.1154 37.8322 19.1233 42.9914 19.0334 48.1459C18.9545 52.6517 14.32 55.2116 10.4553 52.9397C8.58136 51.837 7.74217 50.0761 7.7264 47.9244C7.70904 45.3994 7.72324 42.8727 7.72324 40.3477Z" fill="white" />
                    <path d="M4.00416 49.6012C1.92511 49.144 0.226225 46.8594 0.175747 44.3612C0.142621 42.7 0.166283 41.0372 0.16786 39.376C0.16786 38.3872 0.147353 37.3984 0.177325 36.4096C0.248309 34.0633 1.67903 32.0271 3.77701 31.2503C3.85273 31.2218 3.93475 31.2124 4.00574 31.1965C4.00416 37.3446 4.00416 43.4753 4.00416 49.6012Z" fill="url(#paint1_radial_150_20)" />
                    <path d="M53.0068 49.6121C53.0068 43.4608 53.0068 37.3476 53.0068 31.1726C54.5038 31.6805 55.6396 32.6376 56.2563 34.0789C56.5939 34.8684 56.7942 35.7734 56.8179 36.6325C56.8905 39.1543 56.8573 41.681 56.84 44.2044C56.8211 46.7817 55.2247 49.0124 53.0068 49.6121Z" fill="url(#paint2_radial_150_20)" />
                  </g>
                  <defs>
                    <radialGradient id="paint0_radial_150_20" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2.63936 37.9544) rotate(-37.6429) scale(51.4299 223.285)">
                      <stop stop-color="#FF01F3" />
                      <stop offset="1" stop-color="#0081DF" />
                    </radialGradient>
                    <radialGradient id="paint1_radial_150_20" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.0341284 55.4763) rotate(-82.263) scale(20.5525 24.6138)">
                      <stop stop-color="#C61EEF" />
                      <stop offset="0.605208" stop-color="#5458E6" />
                      <stop offset="1" stop-color="#0182DF" />
                    </radialGradient>
                    <radialGradient id="paint2_radial_150_20" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52.8141 55.4983) rotate(-82.2607) scale(20.5914 24.6675)">
                      <stop stop-color="#0182DF" />
                      <stop offset="1" stop-color="#D91E99" />
                    </radialGradient>
                    <clipPath id="clip0_150_20">
                      <rect width="57" height="66" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h3 class="f-600 ">Tailored E-commerce Web Design and Development</h3>
                <p>We specialize in crafting personalized, adaptable, and responsive  E-commerce solutions to create an appealing impact for your store.</p>
              </div>
            </div>
            <div class="col-3 aos-init aos-animate" data-aos="flip-left" data-aos-duration="1000"style={{width:'285px'}}>
              <div class="process">
                <svg width="59" height="57" viewBox="0 0 59 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_151_26)">
                    <mask id="mask0_151_26" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="59" height="57">
                      <path d="M58.9377 0.881592H0.50293V56.1458H58.9377V0.881592Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_151_26)">
                      <path d="M5.0692 27.0596C7.4189 27.1215 9.28264 26.2497 10.7496 24.446C10.8515 24.5388 10.9571 24.6153 11.039 24.7099C12.3804 26.2588 14.0657 27.0614 16.1206 27.0468C17.0961 27.0396 18.0844 27.0851 19.0418 26.9431C20.7799 26.6865 22.1523 25.7473 23.2443 24.3149C24.946 26.3243 27.1137 27.0814 29.6964 27.0814C32.2827 27.0814 34.4704 26.3661 36.1849 24.324C36.3323 24.4915 36.4597 24.6389 36.5908 24.7845C37.8484 26.1878 39.4009 26.9959 41.2993 27.0359C42.364 27.0596 43.4451 27.0942 44.4935 26.9504C46.2007 26.7174 47.5712 25.8092 48.6614 24.4078C50.152 26.2278 52.0176 27.1415 54.3891 27.0523C54.4037 27.349 54.4273 27.5892 54.4273 27.8295C54.4292 36.6167 54.4292 45.404 54.4292 54.1912C54.4292 55.6836 53.9687 56.1441 52.4762 56.1459C44.1877 56.1478 35.8992 56.1459 27.6088 56.1459C27.3849 56.1459 27.1611 56.1459 26.8698 56.1459C26.8553 55.8292 26.8334 55.5672 26.8334 55.3032C26.8316 48.5144 26.8316 41.7256 26.8316 34.9368C26.8316 33.3242 26.3839 32.8801 24.7713 32.8801C21.2295 32.8801 17.6858 32.8783 14.144 32.8801C12.7862 32.8801 12.2857 33.3752 12.2857 34.7184C12.2839 41.5527 12.2857 48.387 12.2857 55.2213C12.2857 55.4925 12.2857 55.7637 12.2857 56.0859C12.0837 56.1077 11.9126 56.1423 11.7397 56.1423C10.0816 56.1459 8.42357 56.1532 6.7655 56.1423C5.57518 56.135 5.03098 55.6127 5.03098 54.4533C5.02552 45.5077 5.02734 36.5603 5.02916 27.6147C5.02734 27.4564 5.04918 27.3017 5.0692 27.0596ZM47.1708 40.184C47.1708 38.3239 47.1744 36.462 47.1689 34.6019C47.1653 33.4298 46.6266 32.882 45.469 32.8801C42.2457 32.8747 39.0224 32.8747 35.799 32.8801C34.6306 32.882 34.09 33.4225 34.0882 34.591C34.0846 38.2893 34.0846 41.9895 34.0882 45.6879C34.09 46.8454 34.636 47.4151 35.7863 47.4206C39.0096 47.4315 42.233 47.4315 45.4563 47.4206C46.6157 47.4169 47.1635 46.8527 47.1689 45.7006C47.1762 43.8605 47.1708 42.0223 47.1708 40.184Z" fill="url(#paint0_radial_151_26)" />
                      <path d="M29.522 24.1566C28.3189 24.2476 27.0613 23.98 26.0293 23.0154C24.9864 22.0416 24.4695 20.8277 24.6206 19.4117C25.263 13.4146 25.9419 7.42112 26.5953 1.42403C26.639 1.02908 26.77 0.878016 27.1814 0.881656C28.8831 0.899856 30.5849 0.896216 32.2885 0.883476C32.6507 0.879836 32.8145 0.987219 32.8563 1.36943C33.3041 5.51734 33.7682 9.66525 34.2268 13.8113C34.4179 15.5477 34.6145 17.2822 34.7947 19.0185C35.1077 22.0671 33.2276 24.1493 30.1663 24.1566C30.0025 24.1566 29.8441 24.1566 29.522 24.1566Z" fill="url(#paint1_radial_151_26)" />
                      <path d="M35.7246 0.892419C37.6684 0.892419 39.5467 0.879679 41.425 0.9179C41.5743 0.92154 41.789 1.26007 41.8509 1.47666C42.8192 4.89837 43.7674 8.32554 44.7193 11.7509C45.4164 14.2607 46.1153 16.7688 46.8051 19.2805C47.5513 21.9996 45.906 24.1491 43.0776 24.1564C42.6008 24.1582 42.1239 24.16 41.6489 24.1564C39.6632 24.1346 38.1617 22.8478 37.936 20.8858C37.359 15.8624 36.8185 10.8354 36.2615 5.81204C36.1068 4.41423 35.9448 3.01825 35.7901 1.62226C35.7647 1.40022 35.7501 1.18181 35.7246 0.892419Z" fill="white" />
                      <path d="M17.7697 0.91626C19.7462 0.91626 21.6919 0.91626 23.7285 0.91626C23.6321 1.83903 23.5465 2.72904 23.4482 3.61905C22.8112 9.36497 22.1797 15.1127 21.5244 20.8568C21.3006 22.8261 19.8081 24.1311 17.8334 24.1566C17.311 24.1639 16.7886 24.1602 16.2681 24.1566C13.5817 24.1402 11.9054 21.9616 12.6171 19.3716C13.6964 15.4403 14.7975 11.5163 15.8895 7.58859C16.4537 5.55923 17.0198 3.52986 17.5858 1.50232C17.6386 1.30757 17.7059 1.11647 17.7697 0.91626Z" fill="white" />
                      <path d="M14.7301 0.88878C13.9712 3.62979 13.2468 6.24703 12.5224 8.86245C11.3958 12.923 10.2655 16.9817 9.14075 21.0423C8.53831 23.2136 7.30977 24.1546 5.04926 24.1528C4.59606 24.1528 4.13559 24.1764 3.68967 24.1073C1.12521 23.7068 -0.185229 21.3025 0.868584 18.8964C2.35739 15.4929 3.8826 12.104 5.39325 8.70775C6.23957 6.80215 7.10956 4.90747 7.92495 2.98913C8.54195 1.54218 9.59394 0.852379 11.1592 0.88332C12.3131 0.908801 13.4688 0.88878 14.7301 0.88878Z" fill="url(#paint2_radial_151_26)" />
                      <path d="M44.7852 0.996278C46.3449 0.996278 47.8865 0.894355 49.4063 1.03086C50.4783 1.12732 51.0862 1.99549 51.5121 2.96012C53.232 6.85686 54.9666 10.7481 56.6974 14.6394C57.3144 16.0263 57.9369 17.4114 58.5448 18.8019C59.7497 21.5611 58.0734 24.1256 55.0503 24.1547C54.6644 24.1583 54.2786 24.162 53.8927 24.1529C52.3056 24.1128 50.9096 23.1264 50.4801 21.5975C48.5763 14.8142 46.7053 8.0217 44.8234 1.23107C44.8015 1.14916 44.7961 1.0618 44.7852 0.996278Z" fill="url(#paint3_radial_151_26)" />
                    </g>
                  </g>
                  <defs>
                    <radialGradient id="paint0_radial_151_26" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.55784 66.3098) rotate(-44.7551) scale(50.0291 224.56)">
                      <stop stop-color="#5D5AFF" />
                      <stop offset="1" stop-color="#00E0FF" />
                    </radialGradient>
                    <radialGradient id="paint1_radial_151_26" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.0832 31.6084) rotate(-74.0631) scale(26.8032 63.5311)">
                      <stop stop-color="#467CFF" />
                      <stop offset="1" stop-color="#01E1FF" />
                    </radialGradient>
                    <radialGradient id="paint2_radial_151_26" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.208263 31.5863) rotate(-68.3333) scale(27.7117 85.3624)">
                      <stop stop-color="#467CFF" />
                      <stop offset="1" stop-color="#01E1FF" />
                    </radialGradient>
                    <radialGradient id="paint3_radial_151_26" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(44.0777 31.5641) rotate(-68.3724) scale(27.6144 84.9395)">
                      <stop stop-color="#BA24EE" />
                      <stop offset="1" stop-color="#0070E0" />
                    </radialGradient>
                    <clipPath id="clip0_151_26">
                      <rect width="59" height="57" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h3 class="f-600">Safe and Secure Payment Gateways </h3>
                <p>We offer secure payment gateway connections for a dependable online user experience, ensuring transparency in  E-commerce transactions.</p>
              </div>
            </div>
            <div class="col-3 aos-init aos-animate" data-aos="flip-left" data-aos-duration="1000"style={{width:'285px'}}>
              <div class="process">
                <svg width="58" height="59" viewBox="0 0 58 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_151_37)">
                    <mask id="mask0_151_37" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="58" height="59">
                      <path d="M57.8438 0.838867H0.84375V58.8389H57.8438V0.838867Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_151_37)">
                      <path d="M0.846021 13.7015C0.846021 10.5807 0.842056 7.45993 0.848003 4.33711C0.849985 2.17656 2.15031 0.843114 4.25936 0.843114C10.42 0.839079 16.5787 0.839079 22.7394 0.843114C24.8583 0.845131 26.1824 2.16042 26.1864 4.31492C26.1983 10.5827 26.1963 16.8506 26.1864 23.1184C26.1824 25.2749 24.8722 26.6225 22.7632 26.6265C16.6025 26.6386 10.4438 26.6366 4.28315 26.6265C2.13049 26.6225 0.848003 25.2789 0.846021 23.0659C0.844039 19.9451 0.846021 16.8223 0.846021 13.7015Z" fill="url(#paint0_radial_151_37)" />
                      <path d="M45.2015 0.840917C48.2699 0.840917 51.3364 0.836882 54.4048 0.842934C56.5278 0.846969 57.84 2.1663 57.842 4.31474C57.8459 10.5826 57.8459 16.8504 57.842 23.1182C57.84 25.2747 56.5456 26.6223 54.4286 26.6263C48.268 26.6384 42.1093 26.6364 35.9486 26.6263C33.8297 26.6223 32.5055 25.2868 32.5016 23.1424C32.4917 16.8746 32.4917 10.6068 32.5016 4.33895C32.5055 2.14814 33.8237 0.844952 35.9982 0.842934C39.0666 0.8389 42.1351 0.840917 45.2015 0.840917Z" fill="white" />
                      <path d="M0.84589 45.8976C0.84589 42.7768 0.841926 39.656 0.847873 36.5332C0.851837 34.4211 2.14621 33.0574 4.20968 33.0534C10.4179 33.0392 16.6281 33.0372 22.8364 33.0534C24.8562 33.0594 26.1784 34.4171 26.1843 36.4828C26.1982 42.801 26.2002 49.1193 26.1823 55.4375C26.1764 57.5013 24.8344 58.8307 22.8126 58.8327C16.6044 58.8388 10.3941 58.8388 4.18589 58.8327C2.17198 58.8307 0.853819 57.471 0.847873 55.4113C0.839944 52.2421 0.84589 49.0689 0.84589 45.8976Z" fill="white" />
                      <path d="M54.6522 42.6841C54.6522 44.9071 54.6522 46.985 54.6522 49.1415C52.567 49.1415 50.5035 49.1415 48.3687 49.1415C48.3687 51.3182 48.3687 53.4182 48.3687 55.5727C46.2338 55.5727 44.1724 55.5727 42.0217 55.5727C42.0217 53.4566 42.0217 51.3585 42.0217 49.194C39.8928 49.194 37.8511 49.194 35.75 49.194C35.75 47.0314 35.75 44.9354 35.75 42.7325C37.7996 42.7325 39.8591 42.7325 41.9919 42.7325C41.9919 40.5538 41.9919 38.4759 41.9919 36.3477C44.1307 36.3477 46.1922 36.3477 48.3191 36.3477C48.3191 38.4356 48.3191 40.5134 48.3191 42.682C50.442 42.6841 52.5035 42.6841 54.6522 42.6841Z" fill="url(#paint1_radial_151_37)" />
                    </g>
                  </g>
                  <defs>
                    <radialGradient id="paint0_radial_151_37" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.422473 34.869) rotate(-57.4339) scale(33.8677 137.928)">
                      <stop stop-color="#477AFF" />
                      <stop offset="1" stop-color="#01E1FF" />
                    </radialGradient>
                    <radialGradient id="paint1_radial_151_37" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.8051 61.7098) rotate(-57.4219) scale(25.2453 102.833)">
                      <stop stop-color="#26AAFF" />
                      <stop offset="1" stop-color="#01E1FF" />
                    </radialGradient>
                    <clipPath id="clip0_151_37">
                      <rect width="58" height="59" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h3 class="f-600">Effortless API Integration</h3>
                <p>Streamline your business operations with our API integration expertise. Our  E-commerce specialists help you seamlessly integrate digital storefronts using error-free APIs for your  E-commerce venture.. </p>
              </div>
            </div>
            <div class="col-3 aos-init aos-animate" data-aos="flip-left" data-aos-duration="1000"style={{width:'285px'}}>
              <div class="process">
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_151_46)">
                    <mask id="mask0_151_46" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                      <path d="M55.0908 0.338867H0.0908203V55.3389H55.0908V0.338867Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_151_46)">
                      <path d="M0.0908203 8.61499C4.83316 8.61499 9.57551 8.61499 14.394 8.61499C14.394 8.83755 14.394 9.02564 14.394 9.21517C14.394 11.7049 14.4643 14.1961 14.3753 16.6816C14.2762 19.4685 15.31 21.7702 17.0745 23.8234C19.155 26.2443 21.7035 28.0793 24.43 29.6874C25.369 30.2417 26.3324 30.7557 27.2958 31.2654C27.4437 31.3444 27.6935 31.3774 27.8313 31.3056C31.4595 29.439 34.9226 27.3312 37.718 24.2944C39.1165 22.7753 40.2493 21.0896 40.6513 19.0162C40.759 18.4577 40.8035 17.8805 40.8064 17.3105C40.8221 14.624 40.8135 11.939 40.8135 9.25251C40.8135 9.0601 40.8135 8.8677 40.8135 8.64514C45.5961 8.64514 50.3298 8.64514 55.0894 8.64514C55.0894 17.7383 55.0894 26.8086 55.0894 35.9233C54.8841 35.9334 54.6931 35.9491 54.5036 35.9491C36.5436 35.9506 18.5835 35.9506 0.623491 35.9491C0.445455 35.9491 0.26742 35.9262 0.0908203 35.9147C0.0908203 26.8143 0.0908203 17.7139 0.0908203 8.61499Z" fill="url(#paint0_radial_151_46)" />
                      <path d="M0.0912795 39.1382C0.341103 39.1497 0.589491 39.1726 0.839315 39.1726C18.6572 39.1741 36.4751 39.1741 54.293 39.1741C54.5414 39.1741 54.7898 39.1741 55.0769 39.1741C55.0769 41.3523 55.0769 43.4888 55.0769 45.6785C47.6784 45.6785 40.2742 45.6785 32.7938 45.6785C33.1025 47.064 33.3695 48.3893 33.6998 49.6988C33.8735 50.3851 34.1449 51.0485 34.4004 51.7119C34.5268 52.0364 34.778 52.1613 35.1614 52.1555C36.9331 52.1297 38.7049 52.1441 40.5154 52.1441C40.5154 53.2209 40.5154 54.2662 40.5154 55.3402C31.9122 55.3402 23.3235 55.3402 14.6931 55.3402C14.6931 54.295 14.6931 53.2497 14.6931 52.1441C15.2171 52.1441 15.7297 52.1441 16.2437 52.1441C17.533 52.1441 18.8223 52.1599 20.1117 52.1283C20.3299 52.1225 20.6156 51.9617 20.7434 51.7822C20.9688 51.4692 21.1626 51.1016 21.2545 50.7298C21.6608 49.0828 22.027 47.4244 22.4247 45.6957C14.9371 45.6957 7.51421 45.6957 0.0898438 45.6957C0.0912794 43.5089 0.0912795 41.3235 0.0912795 39.1382Z" fill="white" />
                      <path d="M30.6123 0.338867C31.1263 1.38416 31.9763 2.10783 32.9268 2.7238C34.2017 3.55085 35.6346 3.90981 37.1221 4.09503C37.2599 4.11226 37.3977 4.13237 37.5901 4.15678C37.5901 4.38938 37.5901 4.60045 37.5901 4.81008C37.5901 8.89219 37.57 12.9743 37.6016 17.0564C37.6117 18.4032 37.2255 19.5878 36.4817 20.6819C35.3173 22.3949 33.7968 23.7575 32.1285 24.9435C30.7889 25.8955 29.3732 26.7383 27.9762 27.607C27.814 27.7075 27.5096 27.7491 27.3545 27.6644C24.5476 26.1281 21.9058 24.3591 19.7363 21.9713C18.7414 20.8757 17.9057 19.6739 17.6717 18.1577C17.6358 17.9294 17.6186 17.6953 17.6186 17.4627C17.6157 13.1653 17.6157 8.86922 17.6172 4.57173C17.6172 4.44825 17.6301 4.32477 17.6373 4.18693C18.3465 4.04909 19.0486 3.94284 19.7363 3.77341C21.6072 3.31394 23.1535 2.339 24.3193 0.789722C24.4241 0.649009 24.5031 0.489631 24.5936 0.338867C26.6008 0.338867 28.6065 0.338867 30.6123 0.338867ZM26.3251 18.195C29.099 15.4195 31.8901 12.6283 34.6468 9.87C33.9332 9.15782 33.1909 8.41836 32.3654 7.59419C30.3711 9.58857 28.3309 11.6303 26.2533 13.708C25.0745 12.5249 23.903 11.3475 22.7716 10.2117C22.0164 10.967 21.2726 11.7079 20.5547 12.4272C22.4557 14.3297 24.3897 16.2609 26.3251 18.195Z" fill="white" />
                    </g>
                  </g>
                  <defs>
                    <radialGradient id="paint0_radial_151_46" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2.65846 44.6762) rotate(-37.4072) scale(49.7919 215.699)">
                      <stop stop-color="#01E1FF" />
                      <stop offset="1" stop-color="#28A8FF" />
                    </radialGradient>
                    <clipPath id="clip0_151_46">
                      <rect width="56" height="56" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h3 class="f-600">Comprehensive Post-Launch Support</h3>
                <p> We provide 24/7 support for post-project issues, ensuring a seamless digital experience. Our  E-commerce experts monitor the website, handle mobile problems, update apps, and enhance security for uninterrupted service. </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =======  E-commerce Servics Section ======= --> */}
      {/* <!--Integration section start--> */}
      <section id="partner" className="services">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-8 col-sm-6 col-12">
              <h2 className="section-heading text-blue font-semibold">Integration Partners </h2>
              <p>At EPIC-X our endeavors involve a comprehensive range of services within the ecosystem that seamlessly connect with one another through the optimal utilization of APIs, custom development, and middleware. Our team oversees and integrates the complete lifecycle of  E-commerce website design and development, ensuring a smooth experience from the moment of purchase to the final delivery. </p>
              <ul className="seven-list">
                <li>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_147_187)">
                      <path d="M12.4511 23.1429C18.5072 23.1429 23.4168 18.2334 23.4168 12.1772C23.4168 6.12101 18.5072 1.2115 12.4511 1.2115C6.39486 1.2115 1.48535 6.12101 1.48535 12.1772C1.48535 18.2334 6.39486 23.1429 12.4511 23.1429Z" fill="#0B213F" stroke="url(#paint0_radial_147_187)" strokeWidth="1.51194" />
                      <path d="M9.66406 12.416L11.8119 14.564L16.5852 9.79077" stroke="white" strokeWidth="1.51194" strokeLinecap="round" />
                    </g>
                    <defs>
                      <radialGradient id="paint0_radial_147_187" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.442544 31.3825) rotate(-56.98) scale(30.9379 126.906)">
                        <stop stopColor="#38C7E6" />
                        <stop offset="1" stopColor="#BA07CA" />
                      </radialGradient>
                      <clipPath id="clip0_147_187">
                        <rect width="25" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg> Payment gateway integration
                </li>
                <li>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_147_187)">
                      <path d="M12.4511 23.1429C18.5072 23.1429 23.4168 18.2334 23.4168 12.1772C23.4168 6.12101 18.5072 1.2115 12.4511 1.2115C6.39486 1.2115 1.48535 6.12101 1.48535 12.1772C1.48535 18.2334 6.39486 23.1429 12.4511 23.1429Z" fill="#0B213F" stroke="url(#paint0_radial_147_187)" strokeWidth="1.51194" />
                      <path d="M9.66406 12.416L11.8119 14.564L16.5852 9.79077" stroke="white" strokeWidth="1.51194" strokeLinecap="round" />
                    </g>
                    <defs>
                      <radialGradient id="paint0_radial_147_187" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.442544 31.3825) rotate(-56.98) scale(30.9379 126.906)">
                        <stop stopColor="#38C7E6" />
                        <stop offset="1" stopColor="#BA07CA" />
                      </radialGradient>
                      <clipPath id="clip0_147_187">
                        <rect width="25" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg> Enterprise Resource Planning (ERP) and Product Inventory
                </li>
                <li>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_147_187)">
                      <path d="M12.4511 23.1429C18.5072 23.1429 23.4168 18.2334 23.4168 12.1772C23.4168 6.12101 18.5072 1.2115 12.4511 1.2115C6.39486 1.2115 1.48535 6.12101 1.48535 12.1772C1.48535 18.2334 6.39486 23.1429 12.4511 23.1429Z" fill="#0B213F" stroke="url(#paint0_radial_147_187)" strokeWidth="1.51194" />
                      <path d="M9.66406 12.416L11.8119 14.564L16.5852 9.79077" stroke="white" strokeWidth="1.51194" strokeLinecap="round" />
                    </g>
                    <defs>
                      <radialGradient id="paint0_radial_147_187" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.442544 31.3825) rotate(-56.98) scale(30.9379 126.906)">
                        <stop stopColor="#38C7E6" />
                        <stop offset="1" stopColor="#BA07CA" />
                      </radialGradient>
                      <clipPath id="clip0_147_187">
                        <rect width="25" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg> Third-Party Services
                </li>
                <li>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_147_187)">
                      <path d="M12.4511 23.1429C18.5072 23.1429 23.4168 18.2334 23.4168 12.1772C23.4168 6.12101 18.5072 1.2115 12.4511 1.2115C6.39486 1.2115 1.48535 6.12101 1.48535 12.1772C1.48535 18.2334 6.39486 23.1429 12.4511 23.1429Z" fill="#0B213F" stroke="url(#paint0_radial_147_187)" strokeWidth="1.51194" />
                      <path d="M9.66406 12.416L11.8119 14.564L16.5852 9.79077" stroke="white" strokeWidth="1.51194" strokeLinecap="round" />
                    </g>
                    <defs>
                      <radialGradient id="paint0_radial_147_187" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.442544 31.3825) rotate(-56.98) scale(30.9379 126.906)">
                        <stop stopColor="#38C7E6" />
                        <stop offset="1" stopColor="#BA07CA" />
                      </radialGradient>
                      <clipPath id="clip0_147_187">
                        <rect width="25" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg> On-site search
                </li>
                <li>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_147_187)">
                      <path d="M12.4511 23.1429C18.5072 23.1429 23.4168 18.2334 23.4168 12.1772C23.4168 6.12101 18.5072 1.2115 12.4511 1.2115C6.39486 1.2115 1.48535 6.12101 1.48535 12.1772C1.48535 18.2334 6.39486 23.1429 12.4511 23.1429Z" fill="#0B213F" stroke="url(#paint0_radial_147_187)" strokeWidth="1.51194" />
                      <path d="M9.66406 12.416L11.8119 14.564L16.5852 9.79077" stroke="white" strokeWidth="1.51194" strokeLinecap="round" />
                    </g>
                    <defs>
                      <radialGradient id="paint0_radial_147_187" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.442544 31.3825) rotate(-56.98) scale(30.9379 126.906)">
                        <stop stopColor="#38C7E6" />
                        <stop offset="1" stopColor="#BA07CA" />
                      </radialGradient>
                      <clipPath id="clip0_147_187">
                        <rect width="25" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg> Analytics services
                </li>
                <li>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_147_187)">
                      <path d="M12.4511 23.1429C18.5072 23.1429 23.4168 18.2334 23.4168 12.1772C23.4168 6.12101 18.5072 1.2115 12.4511 1.2115C6.39486 1.2115 1.48535 6.12101 1.48535 12.1772C1.48535 18.2334 6.39486 23.1429 12.4511 23.1429Z" fill="#0B213F" stroke="url(#paint0_radial_147_187)" strokeWidth="1.51194" />
                      <path d="M9.66406 12.416L11.8119 14.564L16.5852 9.79077" stroke="white" strokeWidth="1.51194" strokeLinecap="round" />
                    </g>
                    <defs>
                      <radialGradient id="paint0_radial_147_187" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-0.442544 31.3825) rotate(-56.98) scale(30.9379 126.906)">
                        <stop stopColor="#38C7E6" />
                        <stop offset="1" stopColor="#BA07CA" />
                      </radialGradient>
                      <clipPath id="clip0_147_187">
                        <rect width="25" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg> Search Engine Optimization (SEO)
                </li>
              </ul>
            </div>
            <div className="col-8 col-sm-6 col-12">
              <img src={Light_int}  alt="Integration"className="img-fluid w-70 mr-auto d-flex d-dark-mode-none"/>
              <img src={Dark_int} className="img-fluid w-70 mr-auto d-flex d-none d-dark-mode-block" alt="Integration"/>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Integration End--> */}
      <section id="Revenue" className="Revenue">
        <div className="container" data-aos="fade-up">
          <div className="row eightgry">
            <div className="col-md-4 col-12 position-relative">
              <img src={store} alt="store" className="img-fluid w-40 mr-auto d-flex"/>
            </div>
            <div className="col-md-4 col-12 position-relative">
              <div className="eight-mid">
                <h2>Boost Traffic and Enhance Earnings</h2>
                <h5>Get your business up and running.</h5>
              </div>
            </div>
            <div className="col-md-4 col-12 position-relative">
              <div className="eight-mid">
                <button type="submit" name="savecontact" value="Talk to us className" className="submit-cn" id="get_contact">
                  <span className="first-label">
                    <a href="#talk-to-human-form">Get a Quote</a>
                  </span>
                  <span className="second-label" style={{display:'none'}}>Please wait...</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======= Clients Section ======= --> */}
      <section className="py-8 bg-gray-200" style={{background: `url(${course_graphics}) no-repeat`, backgroundSize: 'cover', backgroundPosition: 'top center'}}>
  {/* Content of the section */}
        <div className="container my-lg-8">
            {/* <!-- row --> */}
            <div className="row justify-content-center text-center">
                <div className="col-md-9 col-12">
                    {/* <!-- heading --> */}
                    <h2 className="display-4">We have expertise in</h2>
                    <p className="lead px-lg-8 mb-6">Epic-X brings unparalleled expertise to the forefront of content creation, leveraging cutting-edge technologies to revolutionize the landscape. Our team excels in delivering innovative solutions across various content domains, ensuring a seamless blend of creativity and technical excellence. </p>
                    {/* <!-- button --> */}
                    <div className="d-grid d-md-block">
                        <a href="../sign-up.html" className="btn btn-contact mb-2 mb-md-0">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
      {/* <!-- ======= Testimonials Section ======= --> */}
      <section id="testimonials" className="testimonials">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>Testimonials</h2>
            <p className="text-center section-heading text-blue">What they are saying about us</p>
          </header>
          <div className="testimonial mySwiper">
            <div className="testi-content swiper-wrapper">
              <Swiper slidesPerView={1}
                         spaceBetween={30}
                         grabCursor={true}
                         loop={true}
                          pagination={{
                           clickable: true,
                         }}
                         navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                              }}
                         modules={[Pagination, Navigation]}
                        className="testimonial mySwiper">
              <SwiperSlide className="slide swiper-slide">
              <div className="slide swiper-slide">
                <img src={testimonials_1} alt="testimonials-1" className="image" />
                  <p>I wanted to take a moment to express our profound appreciation for the exceptional work you have done in delivering the Magento commerce solution for our business. Your dedication, expertise, and commitment to excellence have truly made a significant impact on our operations and success. </p>
    
                <i className="bx bxs-quote-alt-left quote-icon"></i>
    
                <div className="details">
                  <span className="name">Coswan Silvers - KARTHIK</span>
                  <span className="job">Ceo &amp; Founder</span>
                </div>
              </div>
              </SwiperSlide>
              <SwiperSlide className="slide swiper-slide">
              <div className="slide swiper-slide">
                <img src={testimonials_2} alt="testimonials-2" className="image" />
                  <p> Your responsive and agile approach to problem-solving, the timeliness of project milestones, and your continuous support in addressing any issues have been invaluable. Your team’s attention to detail, clear communication, and willingness to go the extra mile have truly set you apart.

                    We recognize that a successful Mobile App solution is the result of a collaborative effort, and your contributions have been a vital part of our journey. You’ve not only provided us with a powerful React Native platform but also a sense of confidence in our operations. </p>
    
                <i className="bx bxs-quote-alt-left quote-icon"></i>
    
                <div className="details">
                  <span className="name">Lavanya - T-Nxt</span>
                  <span className="job">Project Manager</span>
                </div>
              </div>
              </SwiperSlide>
              <SwiperSlide className="slide swiper-slide">
              <div className="slide swiper-slide">
                <img src={testimonials_3} alt="testimonials-3" className="image" />
                <p> From the initial project planning to the final implementation, your team’s professionalism and technical proficiency have been outstanding. You’ve not only met but exceeded our expectations in every aspect of this project.

                  The seamless integration of React Web Application platform along with payment gateway has enhanced our online presence, improved user experience, and driven impressive results. Your ability to customize and optimize the solution to meet our unique business needs has been remarkable. </p>
             
    
                <i className="bx bxs-quote-alt-left quote-icon"></i>
    
                <div className="details">
                  <span className="name">Arun Paul</span>
                  <span className="job">Influencer</span>
                </div>
              </div>
              </SwiperSlide>
              </Swiper>
            </div>
            <div className="swiper-button-next nav-btn"></div>
            <div className="swiper-button-prev nav-btn"></div>
          </div>
        </div>
      </section>
      {/* <!-- End Testimonials Section --> */}
      {/* <!-- ======= Contact Section ======= --> */}
      <section id="contact" className="contact">
        <div className="container main-pd" data-aos="fade-up">
          <header className="section-header">
            <p className="text-center section-heading text-blue">Contact Us</p>
          </header>
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-geo-alt"></i>
                    <h3>Address</h3>
                    <p>Kailash, Sobha Verdure, Hari Shree Gardens, Coimbatore, Tamil Nadu 641007,India.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box" style={{height: '14em'}}>
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>+91-8925696660</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box" style={{height: '12.5em'}}>
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>info@epic-x.io </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-clock"></i>
                    <h3>Open Hours</h3>
                    <p>Monday - Friday <br />10:00 AM - 05:00 PM </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 contact-form form-cn sn-full aos-init aos-animate" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
              <form method="post" action="#" id="talk-to-human-form" className="get-form ContactUs">
                <input type="hidden" name="type" value="E-commerce GA"/>
                <div className="row m-0">
                  <input type="text" placeholder="Name" className="w-100" name="name" id="name"/>
                </div>
                <div className="row m-0">
                  <input type="text" placeholder="Email" className="w-100" name="email" id="email"/>
                </div>
                <select name="countryCode" id="" className="select-country" required>
                  <option value="">Country Code</option>
                  <option data-countrycode="GB" value="+971">UAE (+971)</option>
                  <option data-countrycode="IN" value="+91">India (+91)</option>
                  <option data-countrycode="QA" value="+974">Qatar (+974)</option>
                  <option data-countrycode="SA" value="+966">Saudi Arabia (+966)</option>
                  <option data-countrycode="US" value="+1">USA (+1)</option>
                  {/* <!-- <optgroup label="Other countries"> --> */}
                  <option data-countrycode="DZ" value="+213">Algeria (+213)</option>
                  <option data-countrycode="AD" value="+376">Andorra (+376)</option>
                  <option data-countrycode="AO" value="+244">Angola (+244)</option>
                  <option data-countrycode="AI" value="+1264">Anguilla (+1264)</option>
                  <option data-countrycode="AG" value="+1268">Antigua &amp; Barbuda (+1268)</option>
                  <option data-countrycode="AR" value="+54">Argentina (+54)</option>
                  <option data-countrycode="AM" value="+374">Armenia (+374)</option>
                  <option data-countrycode="AW" value="+297">Aruba (+297)</option>
                  <option data-countrycode="AU" value="+61">Australia (+61)</option>
                  <option data-countrycode="AT" value="+43">Austria (+43)</option>
                  <option data-countrycode="AZ" value="+994">Azerbaijan (+994)</option>
                  <option data-countrycode="BS" value="+1242">Bahamas (+1242)</option>
                  <option data-countrycode="BH" value="+973">Bahrain (+973)</option>
                  <option data-countrycode="BD" value="+880">Bangladesh (+880)</option>
                  <option data-countrycode="BB" value="+1246">Barbados (+1246)</option>
                  <option data-countrycode="BY" value="+375">Belarus (+375)</option>
                  <option data-countrycode="BE" value="+32">Belgium (+32)</option>
                  <option data-countrycode="BZ" value="+501">Belize (+501)</option>
                  <option data-countrycode="BJ" value="+229">Benin (+229)</option>
                  <option data-countrycode="BM" value="+1441">Bermuda (+1441)</option>
                  <option data-countrycode="BT" value="+975">Bhutan (+975)</option>
                  <option data-countrycode="BO" value="+591">Bolivia (+591)</option>
                  <option data-countrycode="BA" value="+387">Bosnia Herzegovina (+387)</option>
                  <option data-countrycode="BW" value="+267">Botswana (+267)</option>
                  <option data-countrycode="BR" value="+55">Brazil (+55)</option>
                  <option data-countrycode="BN" value="+673">Brunei (+673)</option>
                  <option data-countrycode="BG" value="+359">Bulgaria (+359)</option>
                  <option data-countrycode="BF" value="+226">Burkina Faso (+226)</option>
                  <option data-countrycode="BI" value="+257">Burundi (+257)</option>
                  <option data-countrycode="KH" value="+855">Cambodia (+855)</option>
                  <option data-countrycode="CM" value="+237">Cameroon (+237)</option>
                  <option data-countrycode="CA" value="+1">Canada (+1)</option>
                  <option data-countrycode="CV" value="+238">Cape Verde Islands (+238)</option>
                  <option data-countrycode="KY" value="+1345">Cayman Islands (+1345)</option>
                  <option data-countrycode="CF" value="+236">Central African Republic (+236)</option>
                  <option data-countrycode="CL" value="+56">Chile (+56)</option>
                  <option data-countrycode="CN" value="+86">China (+86)</option>
                  <option data-countrycode="CO" value="+57">Colombia (+57)</option>
                  <option data-countrycode="KM" value="+269">Comoros (+269)</option>
                  <option data-countrycode="CG" value="+242">Congo (+242)</option>
                  <option data-countrycode="CK" value="+682">Cook Islands (+682)</option>
                  <option data-countrycode="CR" value="+506">Costa Rica (+506)</option>
                  <option data-countrycode="HR" value="+385">Croatia (+385)</option>
                  <option data-countrycode="CU" value="+53">Cuba (+53)</option>
                  <option data-countrycode="CY" value="+90392">Cyprus North (+90392)</option>
                  <option data-countrycode="CY" value="+357">Cyprus South (+357)</option>
                  <option data-countrycode="CZ" value="+42">Czech Republic (+42)</option>
                  <option data-countrycode="DK" value="+45">Denmark (+45)</option>
                  <option data-countrycode="DJ" value="+253">Djibouti (+253)</option>
                  <option data-countrycode="DM" value="+1809">Dominica (+1809)</option>
                  <option data-countrycode="DO" value="+1809">Dominican Republic (+1809)</option>
                  <option data-countrycode="EC" value="+593">Ecuador (+593)</option>
                  <option data-countrycode="EG" value="+20">Egypt (+20)</option>
                  <option data-countrycode="SV" value="+503">El Salvador (+503)</option>
                  <option data-countrycode="GQ" value="+240">Equatorial Guinea (+240)</option>
                  <option data-countrycode="ER" value="+291">Eritrea (+291)</option>
                  <option data-countrycode="EE" value="+372">Estonia (+372)</option>
                  <option data-countrycode="ET" value="+251">Ethiopia (+251)</option>
                  <option data-countrycode="FK" value="+500">Falkland Islands (+500)</option>
                  <option data-countrycode="FO" value="+298">Faroe Islands (+298)</option>
                  <option data-countrycode="FJ" value="+679">Fiji (+679)</option>
                  <option data-countrycode="FI" value="+358">Finland (+358)</option>
                  <option data-countrycode="FR" value="+33">France (+33)</option>
                  <option data-countrycode="GF" value="+594">French Guiana (+594)</option>
                  <option data-countrycode="PF" value="+689">French Polynesia (+689)</option>
                  <option data-countrycode="GA" value="+241">Gabon (+241)</option>
                  <option data-countrycode="GM" value="+220">Gambia (+220)</option>
                  <option data-countrycode="GE" value="+7880">Georgia (+7880)</option>
                  <option data-countrycode="DE" value="+49">Germany (+49)</option>
                  <option data-countrycode="GH" value="+233">Ghana (+233)</option>
                  <option data-countrycode="GI" value="+350">Gibraltar (+350)</option>
                  <option data-countrycode="GR" value="+30">Greece (+30)</option>
                  <option data-countrycode="GL" value="+299">Greenland (+299)</option>
                  <option data-countrycode="GD" value="+1473">Grenada (+1473)</option>
                  <option data-countrycode="GP" value="+590">Guadeloupe (+590)</option>
                  <option data-countrycode="GU" value="+671">Guam (+671)</option>
                  <option data-countrycode="GT" value="+502">Guatemala (+502)</option>
                  <option data-countrycode="GN" value="+224">Guinea (+224)</option>
                  <option data-countrycode="GW" value="+245">Guinea - Bissau (+245)</option>
                  <option data-countrycode="GY" value="+592">Guyana (+592)</option>
                  <option data-countrycode="HT" value="+509">Haiti (+509)</option>
                  <option data-countrycode="HN" value="+504">Honduras (+504)</option>
                  <option data-countrycode="HK" value="+852">Hong Kong (+852)</option>
                  <option data-countrycode="HU" value="+36">Hungary (+36)</option>
                  <option data-countrycode="IS" value="+354">Iceland (+354)</option>
                  <option data-countrycode="IN" value="+91">India (+91)</option>
                  <option data-countrycode="ID" value="+62">Indonesia (+62)</option>
                  <option data-countrycode="IR" value="+98">Iran (+98)</option>
                  <option data-countrycode="IQ" value="+964">Iraq (+964)</option>
                  <option data-countrycode="IE" value="+353">Ireland (+353)</option>
                  <option data-countrycode="IL" value="+972">Israel (+972)</option>
                  <option data-countrycode="IT" value="+39">Italy (+39)</option>
                  <option data-countrycode="JM" value="+1876">Jamaica (+1876)</option>
                  <option data-countrycode="JP" value="+81">Japan (+81)</option>
                  <option data-countrycode="JO" value="+962">Jordan (+962)</option>
                  <option data-countrycode="KZ" value="+7">Kazakhstan (+7)</option>
                  <option data-countrycode="KE" value="+254">Kenya (+254)</option>
                  <option data-countrycode="KI" value="+686">Kiribati (+686)</option>
                  <option data-countrycode="KP" value="+850">Korea North (+850)</option>
                  <option data-countrycode="KR" value="+82">Korea South (+82)</option>
                  <option data-countrycode="KW" value="+965">Kuwait (+965)</option>
                  <option data-countrycode="KG" value="+996">Kyrgyzstan (+996)</option>
                  <option data-countrycode="LA" value="+856">Laos (+856)</option>
                  <option data-countrycode="LV" value="+371">Latvia (+371)</option>
                  <option data-countrycode="LB" value="+961">Lebanon (+961)</option>
                  <option data-countrycode="LS" value="+266">Lesotho (+266)</option>
                  <option data-countrycode="LR" value="+231">Liberia (+231)</option>
                  <option data-countrycode="LY" value="+218">Libya (+218)</option>
                  <option data-countrycode="LI" value="+417">Liechtenstein (+417)</option>
                  <option data-countrycode="LT" value="+370">Lithuania (+370)</option>
                  <option data-countrycode="LU" value="+352">Luxembourg (+352)</option>
                  <option data-countrycode="MO" value="+853">Macao (+853)</option>
                  <option data-countrycode="MK" value="+389">Macedonia (+389)</option>
                  <option data-countrycode="MG" value="+261">Madagascar (+261)</option>
                  <option data-countrycode="MW" value="+265">Malawi (+265)</option>
                  <option data-countrycode="MY" value="+60">Malaysia (+60)</option>
                  <option data-countrycode="MV" value="+960">Maldives (+960)</option>
                  <option data-countrycode="ML" value="+223">Mali (+223)</option>
                  <option data-countrycode="MT" value="+356">Malta (+356)</option>
                  <option data-countrycode="MH" value="+692">Marshall Islands (+692)</option>
                  <option data-countrycode="MQ" value="+596">Martinique (+596)</option>
                  <option data-countrycode="MR" value="+222">Mauritania (+222)</option>
                  <option data-countrycode="YT" value="+269">Mayotte (+269)</option>
                  <option data-countrycode="MX" value="+52">Mexico (+52)</option>
                  <option data-countrycode="FM" value="+691">Micronesia (+691)</option>
                  <option data-countrycode="MD" value="+373">Moldova (+373)</option>
                  <option data-countrycode="MC" value="+377">Monaco (+377)</option>
                  <option data-countrycode="MN" value="+976">Mongolia (+976)</option>
                  <option data-countrycode="MS" value="+1664">Montserrat (+1664)</option>
                  <option data-countrycode="MA" value="+212">Morocco (+212)</option>
                  <option data-countrycode="MZ" value="+258">Mozambique (+258)</option>
                  <option data-countrycode="MN" value="+95">Myanmar (+95)</option>
                  <option data-countrycode="NA" value="+264">Namibia (+264)</option>
                  <option data-countrycode="NR" value="+674">Nauru (+674)</option>
                  <option data-countrycode="NP" value="+977">Nepal (+977)</option>
                  <option data-countrycode="NL" value="+31">Netherlands (+31)</option>
                  <option data-countrycode="NC" value="+687">New Caledonia (+687)</option>
                  <option data-countrycode="NZ" value="+64">New Zealand (+64)</option>
                  <option data-countrycode="NI" value="+505">Nicaragua (+505)</option>
                  <option data-countrycode="NE" value="+227">Niger (+227)</option>
                  <option data-countrycode="NG" value="+234">Nigeria (+234)</option>
                  <option data-countrycode="NU" value="+683">Niue (+683)</option>
                  <option data-countrycode="NF" value="+672">Norfolk Islands (+672)</option>
                  <option data-countrycode="NP" value="+670">Northern Marianas (+670)</option>
                  <option data-countrycode="NO" value="+47">Norway (+47)</option>
                  <option data-countrycode="OM" value="+968">Oman (+968)</option>
                  <option data-countrycode="PW" value="+680">Palau (+680)</option>
                  <option data-countrycode="PA" value="+507">Panama (+507)</option>
                  <option data-countrycode="PG" value="+675">Papua New Guinea (+675)</option>
                  <option data-countrycode="PY" value="+595">Paraguay (+595)</option>
                  <option data-countrycode="PE" value="+51">Peru (+51)</option>
                  <option data-countrycode="PH" value="+63">Philippines (+63)</option>
                  <option data-countrycode="PL" value="+48">Poland (+48)</option>
                  <option data-countrycode="PT" value="+351">Portugal (+351)</option>
                  <option data-countrycode="PR" value="+1787">Puerto Rico (+1787)</option>
                  <option data-countrycode="QA" value="+974">Qatar (+974)</option>
                  <option data-countrycode="RE" value="+262">Reunion (+262)</option>
                  <option data-countrycode="RO" value="+40">Romania (+40)</option>
                  <option data-countrycode="RU" value="+7">Russia (+7)</option>
                  <option data-countrycode="RW" value="+250">Rwanda (+250)</option>
                  <option data-countrycode="SM" value="+378">San Marino (+378)</option>
                  <option data-countrycode="ST" value="+239">Sao Tome &amp; Principe (+239)</option>
                  <option data-countrycode="SA" value="+966">Saudi Arabia (+966)</option>
                  <option data-countrycode="SN" value="+221">Senegal (+221)</option>
                  <option data-countrycode="CS" value="+381">Serbia (+381)</option>
                  <option data-countrycode="SC" value="+248">Seychelles (+248)</option>
                  <option data-countrycode="SL" value="+232">Sierra Leone (+232)</option>
                  <option data-countrycode="SG" value="+65">Singapore (+65)</option>
                  <option data-countrycode="SK" value="+421">Slovak Republic (+421)</option>
                  <option data-countrycode="SI" value="+386">Slovenia (+386)</option>
                  <option data-countrycode="SB" value="+677">Solomon Islands (+677)</option>
                  <option data-countrycode="SO" value="+252">Somalia (+252)</option>
                  <option data-countrycode="ZA" value="+27">South Africa (+27)</option>
                  <option data-countrycode="ES" value="+34">Spain (+34)</option>
                  <option data-countrycode="LK" value="+94">Sri Lanka (+94)</option>
                  <option data-countrycode="SH" value="+290">St. Helena (+290)</option>
                  <option data-countrycode="KN" value="+1869">St. Kitts (+1869)</option>
                  <option data-countrycode="SC" value="+1758">St. Lucia (+1758)</option>
                  <option data-countrycode="SD" value="+249">Sudan (+249)</option>
                  <option data-countrycode="SR" value="+597">Suriname (+597)</option>
                  <option data-countrycode="SZ" value="+268">Swaziland (+268)</option>
                  <option data-countrycode="SE" value="+46">Sweden (+46)</option>
                  <option data-countrycode="CH" value="+41">Switzerland (+41)</option>
                  <option data-countrycode="SI" value="+963">Syria (+963)</option>
                  <option data-countrycode="TW" value="+886">Taiwan (+886)</option>
                  <option data-countrycode="TJ" value="+7">Tajikstan (+7)</option>
                  <option data-countrycode="TH" value="+66">Thailand (+66)</option>
                  <option data-countrycode="TG" value="+228">Togo (+228)</option>
                  <option data-countrycode="TO" value="+676">Tonga (+676)</option>
                  <option data-countrycode="TT" value="+1868">Trinidad &amp; Tobago (+1868)</option>
                  <option data-countrycode="TN" value="+216">Tunisia (+216)</option>
                  <option data-countrycode="TR" value="+90">Turkey (+90)</option>
                  <option data-countrycode="TM" value="+7">Turkmenistan (+7)</option>
                  <option data-countrycode="TM" value="+993">Turkmenistan (+993)</option>
                  <option data-countrycode="TC" value="+1649">Turks &amp; Caicos Islands (+1649)</option>
                  <option data-countrycode="TV" value="+688">Tuvalu (+688)</option>
                  <option data-countrycode="UG" value="+256">Uganda (+256)</option>
                  {/* <!-- <option data-countryCode="GB" value="+44">UK (+44)</option> --> */}
                  <option data-countrycode="UA" value="+380">Ukraine (+380)</option>
                  <option data-countrycode="AE" value="+971">UAE (+971)</option>
                  <option data-countrycode="UY" value="+598">Uruguay (+598)</option>
                  {/* <!-- <option data-countryCode="US" value="+1">USA (+1)</option> --> */}
                  <option data-countrycode="UZ" value="+7">Uzbekistan (+7)</option>
                  <option data-countrycode="VU" value="+678">Vanuatu (+678)</option>
                  <option data-countrycode="VA" value="+379">Vatican City (+379)</option>
                  <option data-countrycode="VE" value="+58">Venezuela (+58)</option>
                  <option data-countrycode="VN" value="+84">Vietnam (+84)</option>
                  <option data-countrycode="VG" value="+84">Virgin Islands - British (+1284)</option>
                  <option data-countrycode="VI" value="+84">Virgin Islands - US (+1340)</option>
                  <option data-countrycode="WF" value="+681">Wallis &amp; Futuna (+681)</option>
                  <option data-countrycode="YE" value="+969">Yemen (North)(+969)</option>
                  <option data-countrycode="YE" value="+967">Yemen (South)(+967)</option>
                  <option data-countrycode="ZM" value="+260">Zambia (+260)</option>
                  <option data-countrycode="ZW" value="+263">Zimbabwe (+263)</option>
                </select>
                <div className="row m-0 phone-number-contr">
                  <input type="number" placeholder="Contact Number" className="w-100" name="contact_number" id="contact_number"/>
                </div>
                <select name="budget" id="budget" className="w-100">
                  <option value="">Select Budget</option>
                  <option value="$5,000 below">$5,000 below</option>
                  <option value="$5,000 to $10,000">$5,000 to $10,000</option>
                  <option value="$10,000 to $15,000">$10,000 to $15,000</option>
                  <option value="$15,000 and above">$15,000 and above</option>
                </select>
                <textarea name="content_text" placeholder="What's your Project about?"></textarea>
                <div class="row m-0">
                  <button type="submit" name="savecontact" value="Talk to us className" className="submit-cn col-4" id="get_contact">
                    <span className="first-label">Submit</span>
                    <span className="second-label" style={{display:"none"}}>Please wait...</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Section --> */}
    </main>
    // End Main
  )
}

export default MainContent